import React from 'react';
import { FormikProps } from "formik"
import { IAuthUser, IExtendedEvent } from "../../store/types"
import Translator from "../../services/translate-factory"
import { ClassroomFeatureOptions, ClassroomSeatingArrangementOptions, ClassroomSeatingTypeOptions } from '../../store/constants/classroom-const';
import { MdLocationOn, MdEvent } from "react-icons/md";
import cn, { flexCenter, flexCol, flexIC, flexJB, flexRow, gap2 } from '../../components/ui/Tailwind';
import Button from '../../components/button';
import { Badge } from '../../components/ui/Badge';
import SelectField from '../../components/Input/select-field';
import { RBCEventStatus, RBCEventStatuses } from './constants';
import { Log } from 'ng2-logger';
import { UserRole } from '../../store/constants/enums';
interface IPreviewProps {
    formikProps: FormikProps<IExtendedEvent>
    setShowPlace?: (value: boolean) => void
    setShowEdit?: (value: boolean) => void,
    user?: IAuthUser
}
const L = Log.create('Preview.tsx')
const Preview: React.FC<IPreviewProps> = ({ formikProps: props, setShowEdit, setShowPlace, user }) => {
    const T = new Translator()
    const PreviewItem = ({ title, children, className }: { title: string, children: React.ReactNode, className?: string }) => {
        return <p className={cn(flexCol, "tw-text-lg", className)}><strong>{title}</strong> {children}</p>
    }

    const getSittingType = (event: IExtendedEvent) => {
        if (!event.selectedPlace)
            return ""
        const seatingType = event.selectedPlace.seating_type
        const findLocalized = ClassroomSeatingTypeOptions(T).find(_ => _.value === seatingType)
        return findLocalized && findLocalized.label || seatingType
    }

    const getSeatingArrangement = (event: IExtendedEvent) => {
        if (!event.selectedPlace)
            return ""
        const seatingArrangement = event.selectedPlace.seating_arrangement
        const findLocalized = ClassroomSeatingArrangementOptions(T).find(_ => _.value === seatingArrangement)
        return findLocalized && findLocalized.label || seatingArrangement
    }

    function formatDate(value: string | Date) {
        if (typeof value === 'string') {
            try {
                return getLocalizedDate(new Date(value), T)
            } catch (error) {
                return value;
            }

        } else if (value instanceof Date) {
            return getLocalizedDate(value, T)
        } else {
            return '';
        }
    }
    const PreviewMerged = ({ props }: { props: FormikProps<IExtendedEvent> }) => {

        return <>
            <div className=" tw-border-gray-200 tw-bg-white tw-border-solid tw-border tw-w-full tw-rounded-lg tw-p-8 tw-space-y-4">
                {/* Etkinlik Detayları Kartı */}
                <div className={cn(flexRow, flexJB, gap2)}>
                    <h2 className={cn(flexRow, flexIC, 'tw-text-2xl tw-font-bold tw-text-gray-800')}><MdEvent />{T.t("gen_event_detail")}</h2>
                    {isAuthorized(user) && setShowEdit && <Button icon='edit' borderColor='light-gray' onClick={() => setShowEdit(true)}>
                        {T.t("gen_edit")}
                    </Button>}
                </div>
                <div className="tw-grid tw-grid-cols-1 tw-text-gray-600">
                    {
                        [
                            { title: T.t("gen_event_name"), value: props.values.title },
                            { title: T.t("gen_description"), value: props.values.description },
                            { title: T.t("gen_date_and_time"), value: `${formatDate(props.values.start_date)} - ${formatDate(props.values.end_date)}` },
                            { title: T.t("gen_event_type"), value: props.values.event_type && props.values.event_type.label },
                            {
                                title: T.t("gen_responsibles"),
                                value: <ol>
                                    {props.values.responsibles && props.values.responsibles.length > 0 ? props.values.responsibles.map(_ => <li>{_.label}</li>) : "-"}
                                </ol>
                            },
                        ].map((item, index) => <PreviewItem key={index} title={item.title}>{item.value || '-'}</PreviewItem>)
                    }
                </div>
            </div>
            <div className="tw-border-gray-200 tw-bg-white tw-border-solid tw-border tw-w-full tw-rounded-lg tw-p-8 tw-space-y-4">
                {/* Mekan Detayları Kartı */}

                <div className={cn(flexRow, flexJB, gap2)}>
                    <h2 className={cn(flexRow, flexIC, 'tw-text-2xl tw-font-bold tw-text-gray-800')}><MdLocationOn />{T.t("gen_place_info")}</h2>
                    {isAuthorized(user) && setShowPlace && <Button icon='edit' borderColor='light-gray' onClick={() => setShowPlace(true)}>
                        {T.t("gen_change_location")}
                    </Button>}
                </div>
                <div className="tw-grid tw-grid-cols-2 tw-text-gray-600">
                    {props.values.selectedPlace ?
                        [
                            { title: T.t("gen_campus"), value: props.values.selectedPlace.campus_name },
                            { title: T.t("gen_building"), value: props.values.selectedPlace.building_name },
                            { title: T.t("gen_floor"), value: props.values.selectedPlace.floor },
                            { title: T.t("gen_classroom"), value: props.values.selectedPlace.name },
                            { title: T.t("gen_lecture_capacity"), value: props.values.selectedPlace.lecture_capacity },
                            { title: T.t("gen_exam_capacity"), value: props.values.selectedPlace.exam_capacity, classNames: "tw-col-span-2" },
                            { title: T.t("gen_seating_arrangement"), value: getSeatingArrangement(props.values), classNames: "tw-col-span-2" },
                            { title: T.t("gen_sitting_type"), value: getSittingType(props.values) },
                            {
                                title: T.t("gen_feature"),
                                classNames: "tw-col-span-2",
                                value: props.values.selectedPlace.feature && props.values.selectedPlace.feature.map(i => {
                                    const localized = ClassroomFeatureOptions(T).find(_ => _.value === i)
                                    return localized && localized.label || i
                                }).join(", ")
                            },
                        ].map((item, index) => <PreviewItem key={index} title={item.title}>{item.value || '-'}</PreviewItem>)
                        : <div>{T.t("gen_no_releted_place_found")}</div>
                    }
                </div>
            </div>
        </>
    }


    return <>
        <div className="tw-duration-150 tw-transition-all tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-4 tw-w-full ">
            <PreviewHeader props={props} T={T} user={user} />
            <PreviewMerged props={props} />
        </div>
    </>
}

interface IPreviewHeaderProps {
    props: FormikProps<IExtendedEvent>, T: Translator
    user?: IAuthUser
}

export const isAuthorized = (user: IAuthUser | undefined) => {
    if (!user || !user.role) return false
    return [UserRole.SYSTEM, UserRole.PANEL, UserRole.MANAGER].includes(user.role as UserRole)
}

export const PreviewHeader: React.FC<IPreviewHeaderProps> = ({ props, T, user }) => {

    return <div className={cn(
        "tw-duration-150 tw-transition-all",
        "tw-border-gray-200 tw-bg-white tw-border-solid tw-col-span-2 tw-border tw-w-full tw-rounded-lg tw-px-8 tw-py-4 tw-space-y-1 tw-border-b-2",
        {
            "tw-border-b-yellow-500": props.values.status_approval === RBCEventStatus.PENDING,
            "tw-border-b-green-500": props.values.status_approval === RBCEventStatus.APPROVED,
            "tw-border-b-red-500": props.values.status_approval === RBCEventStatus.DENIED,
        }
    )}>
        <div className={cn(flexRow, flexIC, flexJB)}>
            <h2 style={{ marginBottom: 0 }} className={cn(
                "tw-truncate",
                {
                    "tw-italic": !props.values.title || props.values.title.length === 0
                }
            )}>{props.values.title && props.values.title.length > 0 ? props.values.title : T.t("gen_please_enter_title")}</h2>
            <div className={cn(flexCenter, gap2)}>
                {isAuthorized(user) && <div className='tw-w-48'>
                    <SelectField marginBottom={false} T={T} id="change-status" options={
                        Object.keys(RBCEventStatuses(T)).map((key) => ({
                            label: RBCEventStatuses(T)[key as keyof typeof RBCEventStatuses],
                            value: Number(key)
                        }))
                    }
                        value={{ label: props.values.status_approval && RBCEventStatuses(T)[props.values.status_approval], value: props.values.status_approval }}
                        onChange={(option) => { props.setFieldValue("status_approval", option.value) }} placeholder="Change Status" />
                </div>}
            </div>
        </div>
        <div className={cn(flexRow, flexIC, flexJB)}>
            <Badge _color={getBadgeColor(props.values.status_approval)} className="tw-duration-150 tw-transition-all">
                {props.values.status_approval && RBCEventStatuses(T)[props.values.status_approval]}
            </Badge>

            {props.values.user_approved_by && getLocalizedInformation(props.values, T)}
        </div>
    </div>
}


const getBadgeColor = (status: RBCEventStatus | undefined) => {
    switch (status) {
        case RBCEventStatus.PENDING:
            return 'yellow'
        case RBCEventStatus.APPROVED:
            return 'green'
        case RBCEventStatus.DENIED:
            return 'red'
        default:
            return 'gray'
    }
}

const getBorderColor = (status: RBCEventStatus | undefined) => {
    switch (status) {
        case RBCEventStatus.PENDING:
            return 'tw-text-yellow-700'
        case RBCEventStatus.APPROVED:
            return 'tw-text-green-700'
        case RBCEventStatus.DENIED:
            return 'tw-text-red-700'
        default:
            return 'tw-text-gray-700'
    }
}

export const getLocalizedDate = (date: Date, T: Translator) => {
    const selectedLang = T.getSelectedLanguage()
    return new Intl.DateTimeFormat(selectedLang, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    })
        .format(date)
        .toString();
}

const getCreateInformation = (values: IExtendedEvent, T: Translator) => {
    let name = values.user_created_by && values.user_created_by.label
    if (values.user_created_by && values.user_created_by.value === 1) {
        name = T.t('gen_admin')
    }

    return <div className={cn("tw-text-gray-700 tw-duration-150 tw-transition-all", getBorderColor(values.status_approval))}>
        <span
            dangerouslySetInnerHTML={{
                __html: T.t('gen_created_by', { name: name, date: getLocalizedDate(values.changed_at, T) }),
            }}
        />
    </div>
}

const getLocalizedInformation = (values: IExtendedEvent, T: Translator) => {

    let name = values.user_approved_by && values.user_approved_by.label
    if (values.user_approved_by) {

        if (values.user_approved_by.value === 1) {
            name = T.t('gen_admin')
        } else if (values.user_approved_by.value === 0) {
            return getCreateInformation(values, T)
        }

        return <div className={cn("tw-duration-150 tw-transition-all", getBorderColor(values.status_approval))} >
            <span
                dangerouslySetInnerHTML={{
                    __html: T.t('gen_changed_by', { name: name, date: getLocalizedDate(values.changed_at, T) }),
                }}
            />
        </div>
    }
}



export default Preview