import React, { useEffect, useState, useReducer } from 'react';
import * as Constants from '../../../store/constants/all';
import * as Types from '../../../store/types';
import * as Actions from '../../../store/actions/general';
import * as GT from '../../../tools/general-tools';
import { routes as Routes } from '../../../store/constants/routes';
import Translator from '../../../services/translate-factory';
import Table from '../../../components/table/Table'
import { BsImageFill } from 'react-icons/bs';
import ImagePopup from '../../../components/image/ImagePopup';
import { FaArrowRight } from 'react-icons/fa';
import { connect } from 'react-redux';
import { CampusSearchInitialValues } from "../../../store/constants/campus-const";
import { Badge, Image } from 'antd';
import { get } from 'lodash';
import { getConstFromType } from '../tools/utils';
import { TableColumn, TableType } from '../tools/interfaces';
import { Log } from 'ng2-logger';
import fallback from '../../../assets/images/default-fallback-image.png';
import { UserRole } from '../../../store/constants/enums';

const T = Translator.create();
const L = Log.create('CampusSketchList');

interface CampusSketchListProps {
    dispatch?: any,
    user?: Types.IAuthUser,
    selectOptions?: Types.ISelectOption[]
}

const CampusSketchList = ({ dispatch, selectOptions, user }: CampusSketchListProps) => {

    const [buildingPopup, setBuildingPopup] = useState(false);
    const [showImageArray, setShowImageArray] = useState<string[]>([]);

    const langChanged = useReducer(() => ({}), {})[1] as () => void;

    useEffect(() => {
        T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, langChanged);
        T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, langChanged);
    }, [])

    const onRowSelected = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (e && e.currentTarget) {
            const id: string = e.currentTarget.dataset.id || '';
            dispatch(Actions.Navigation(GT.Route(Routes.SKETCHES, `?type=campus&id=${id}`)));
        }
    };
    const getColumnComponents = (): TableColumn[] => {
        const adminComponents: TableColumn[] = [
            {
                __componentType: 'chexbox',
                widthPercentage: 5,
                __headerType: 'checkAll',
                parseName: 'campus_id',
            },
            {
                __componentType: 'activeness',
                widthPercentage: 5,
                __headerType: 'default',
                parseName: 'status_images',
                title: T.t("gen_status"),
            },
        ]

        const mainComponents: TableColumn[] = [
            {
                widthPercentage: 15,
                __componentType: 'image',
                __headerType: 'default',
                customImageSrc: (item: Types.ICampusItem) => {
                    return item.sketches && item.sketches[0] && item.sketches[0].src || ""
                },
                title: T.t("gen_sketch"),
                parseName: "",
            },
            {
                widthPercentage: 10,
                __componentType: 'oneLine',
                __headerType: 'default',
                parseName: "campus_code",
                title: T.t("gen_code"),
            },
            {
                widthPercentage: 20,
                __componentType: 'oneLine',
                __headerType: 'sort',
                parseName: "name",
                title: T.t("gen_campus"),
            },
            {
                widthPercentage: 20,
                maxLines: 5,
                __componentType: 'multiLine',
                __headerType: 'default',
                title: T.t("gen_connected_buildings"),
                parseName: "connectedBuildings",
            },
            {
                widthPercentage: 20,
                __componentType: 'oneLine',
                __headerType: 'default',
                title: T.t("gen_address"),
                parseName: "address",
            },
            {
                widthPercentage: 10,
                __componentType: 'oneLine',
                __headerType: 'default',
                customRender: (item: Types.ICampusItem) => {
                    const images = item.photos != undefined ? item.photos : [];
                    const mergedImageList: string[] = [];
                    images && images.forEach(j => {
                        mergedImageList.push(j.src);
                    });

                    return <td data-label={T.t('gen_name')} className="text-center">
                        <Badge count={mergedImageList.length} size='small'>
                            <button
                                disabled={images && images.length < 1}
                                style={{ border: 'none', backgroundColor: 'transparent' }}
                                onClick={() => {
                                    setBuildingPopup(true);
                                    setShowImageArray(mergedImageList);
                                }}
                                className="disabled:tw-opacity-50"
                                title={images && images.length < 1 ? T.t('gen_no_image') : T.t("gen_show_images")}
                            >
                                <BsImageFill style={{ fontSize: '24px' }} />
                            </button>
                        </Badge>
                    </td>
                },
                title: T.t("gen_images"),
                parseName: "",
            },
            {
                widthPercentage: 5,
                __componentType: 'oneLine',
                __headerType: 'default',
                customRender: (item: Types.ICampusItem) => {
                    return <td className='text-center tw-duration-150 tw-opacity-0 group-hover:tw-opacity-100'>
                        <button
                            style={{ border: 'none', backgroundColor: 'transparent' }}
                            id={item.campus_id && item.campus_id.toString() || ""}
                            data-toggle="tooltip"
                            data-id={item.campus_id}
                            onClick={onRowSelected}
                        >
                            <FaArrowRight style={{ fontSize: '24px' }} />
                        </button>
                    </td>
                },
                title: "",
                parseName: "",
            },
        ]

        const adminRoles: string[] = [UserRole.SYSTEM, UserRole.MANAGER, UserRole.PANEL]

        if (user && adminRoles.includes(user.role)) {
            mainComponents.splice(0, 0, ...adminComponents);
        }

        return mainComponents
    }
    return (
        <>
            <Table
                type={TableType.SKETCH_CAMPUS}
                initialFilter={CampusSearchInitialValues}
                filterComponent={[
                    {
                        fieldName: "campus_code",
                        type: "input",
                        title: T.t("gen_code")
                    },
                    {
                        fieldName: "name",
                        type: "input",
                        title: T.t("gen_campus")
                    },
                    {
                        fieldName: "building",
                        type: "dropdown-multi",
                        placeholder: T.t("gen_select_building"),
                        title: T.t("gen_connected_buildings"),
                        options: selectOptions,
                    },
                ]}
                rowClassname="tw-group"
                actions={[
                    {
                        actionType: 'active/passive',
                        showOnSelect: true,
                    }
                ]}
                columnComponent={getColumnComponents()}>
            </Table>
            <ImagePopup open={buildingPopup} setClose={setBuildingPopup} imageArray={showImageArray} />
        </>
    );

}

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
    return equal(prev, next)
};

const mapStateToProps = (store: Types.IPersistedState, ownProps: CampusSketchListProps): CampusSketchListProps => {
    if (!store || !store.state) {
        return ownProps;
    }
    const config = getConstFromType.sketch_campus
    if (!config) {
        return ownProps;
    }
    const statePaths = config.statePaths;

    const newProps: any = Object.assign({}, ownProps, {
        selectOptions: get(store.state, statePaths.selectOptions),
        user: store.state.user,
    });

    return newProps;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, { areStatesEqual })(CampusSketchList);

export default container;