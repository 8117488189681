import React from 'react';
import { Log } from 'ng2-logger';
import { MdEvent, MdEventNote, MdSchool, MdLocationCity } from 'react-icons/md';
import { CgListTree } from "react-icons/cg";
import { FaUniversity, FaDoorOpen, FaBuilding, FaChartPie, FaUser, FaGraduationCap, FaSync, FaChalkboardTeacher, FaBook } from "react-icons/fa";
import { NavItem } from './dropdown-elements';
import Translator from '../../services/translate-factory';
import { SettingsDashboardStatus } from '../../store/constants/dashboard-const';
import { SettingsEventModuleStatus } from '../../store/constants/setting-const';
import * as Types from '../../store/types';
import { routes as Routes } from '../../store/constants/routes';
import { TermType } from '../../store/constants/general';

const L = Log.create('HeaderItems');

// #region CONDITIONS
const isEventModuleActive = (props: Types.IPageProps) => {
    const { general_settings } = props;
    return general_settings && general_settings.event_module_status && general_settings.event_module_status === SettingsEventModuleStatus.Active;
};

const isCoursePlanningActive = (props: Types.IPageProps) => {
    const { general_settings } = props;
    return general_settings && general_settings.dashboard_status != SettingsDashboardStatus.ActiveForExamTerms;
};

const isExamPlanningActive = (props: Types.IPageProps) => {
    const { general_settings } = props;
    return general_settings && general_settings.dashboard_status != SettingsDashboardStatus.ActiveForCourseTerms;
};
// #endregion

// #region HEADER ELEMENTS
export const dashboardItems: (T: Translator) => NavItem[] = (T: Translator) => [
    {
        key: 0,
        label: T.t('gen_course_planning'),
        icon: <FaChartPie />,
        to: Routes.DASHBOARD_COURSE_PLANNING,
        show: (props, states) => {
            return isCoursePlanningActive(props)
        },
    },
    {// to do after 2926: no reports for exam planning!
        key: 0,
        label: T.t('gen_exam_planning'),
        icon: <FaChartPie />,
        to: Routes.DASHBOARD_EXAM_PLANNING,
        show: (props, states) => {
            return isExamPlanningActive(props)
        },
    },
]

export const termsItems: (T: Translator) => NavItem[] = (T: Translator) => [
    {
        key: 0,
        label: T.t('gen_course_terms'),
        icon: <MdSchool />,
        to: Routes.COURSE_PERIODS,
    },
    {
        key: 1,
        label: T.t('gen_exam_terms'),
        icon: <MdEventNote />,
        to: Routes.EXAM_PERIODS,
    },
    {
        key: 2,
        label: T.t('gen_event_terms'),
        icon: <MdEvent />,
        to: Routes.EVENT_PERIODS,
        show: (props, states) => {
            return isEventModuleActive(props)
        },
    },
]


export const syncDefinitionItems: (T: Translator) => NavItem[] = (T: Translator) => [
    {
        key: 2,
        label: T.t('gen_campuses'),
        to: Routes.SYNC_CAMPUS,
        icon: <FaSync />,
    },
    {
        key: 3,
        label: T.t('gen_buildings'),
        to: Routes.SYNC_BUILDING,
        icon: <FaSync />,
    },
    {
        key: 4,
        label: T.t('gen_classrooms'),
        to: Routes.SYNC_CLASSROOM,
        icon: <FaSync />,
    },
    {
        key: 5,
        label: T.t('gen_courses'),
        to: Routes.SYNC_COURSE,
        icon: <FaSync />,
        show: (props, state) => {
            return props.term_type !== TermType.UNSELECTED
        }
    },
    {
        key: 6,
        label: T.t('gen_faculties'),
        to: Routes.SYNC_FACULTY,
        icon: <FaSync />,
    },
    {
        key: 7,
        label: T.t('gen_instructors'),
        to: Routes.SYNC_INSTRUCTOR,
        icon: <FaSync />,
        show: (props, state) => {
            return props.term_type !== TermType.UNSELECTED
        }
    },
    {
        key: 8,
        label: T.t('gen_programs'),
        to: Routes.SYNC_PROGRAM,
        icon: <FaSync />,
    },
    {
        key: 9,
        label: T.t('gen_students'),
        to: Routes.SYNC_STUDENT,
        icon: <FaSync />,
        show: (props, state) => {
            return props.term_type === TermType.EXAM
        }
    },
]
export const staticDefinitionsItems: (T: Translator) => NavItem[] = (T: Translator) => [
    {
        key: '0',
        label: T.t('gen_campuses'),
        icon: <FaUniversity />,
        to: Routes.STATIC_CAMPUS,
    },
    {
        key: '2',
        label: T.t('gen_buildings'),
        icon: <FaBuilding />,
        to: Routes.STATIC_BUILDING,
    },
    {
        key: '3',
        label: T.t('gen_classrooms'),
        icon: <FaDoorOpen />,
        to: Routes.STATIC_CLASSROOM,
    },
    {
        key: '5',
        type: 'divider'
    },
    {
        key: '1',
        label: T.t('gen_faculties'),
        icon: <MdLocationCity />,
        to: Routes.STATIC_FACULTY,
    },
    {
        key: '4',
        label: T.t('gen_programs'),
        icon: <FaGraduationCap />,
        to: Routes.STATIC_PROGRAM,
    },
];



export const termDefinitionsItems: (T: Translator) => NavItem[] = (T: Translator) => [
    {
        key: '0',
        label: T.t('gen_campuses'),
        icon: <FaUniversity />,
        to: Routes.CAMPUS,
    },
    {
        key: '2',
        label: T.t('gen_buildings'),
        icon: <FaBuilding />,
        to: Routes.BUILDING,
    },
    {
        key: '3',
        label: T.t('gen_classrooms'),
        icon: <FaDoorOpen />,
        to: Routes.CLASSROOM,
    },
    {
        key: '5',
        type: 'divider'
    },
    {
        key: '1',
        label: T.t('gen_faculties'),
        icon: <MdLocationCity />,
        to: Routes.FACULTY,
    },
    {
        key: '4',
        label: T.t('gen_programs'),
        icon: <FaGraduationCap />,
        to: Routes.PROGRAM,
    },
    {
        key: '6',
        label: T.t('gen_instructors'),
        icon: <FaChalkboardTeacher />,
        to: Routes.INSTRUCTOR,
    },
    {
        key: '7',
        label: T.t('gen_courses'),
        icon: <FaBook />,
        to: Routes.COURSE,
    },
    {
        key: '8',
        label: T.t('gen_activity_relations'),
        icon: <CgListTree />,
        to: Routes.ACTIVITY_RELATIONS,
        show: (props, states) => {
            return props.term_type === TermType.COURSE
        }
    },
];