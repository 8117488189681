import { connect } from 'react-redux';
import { Formik, FormikActions, FormikProps } from 'formik';
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import * as Types from '../../store/types';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import Translator from '../../services/translate-factory';
import Select from 'react-select';
import Button from '../../components/button';
import Spinner from '../../components/templates/spinner';
import { RBCEventStatus, RBCEventStatuses, RBCEventTypes } from './constants';
import { ValueType } from 'react-select/lib/types';
import AddEventPeriod from './add';
import { debounce } from 'lodash';
import { Log } from 'ng2-logger';
import { isAuthorized } from './add-preview';

const L = Log.create("EventCalendarFilters.tsx")
const initialFilter: Types.IFilterEvent = {}

interface EventCalendarFiltersProps {
    selectOptions?: any;
    dispatch?: any;
    user?: Types.IAuthUser;
}

const EventCalendarFiltersIn: React.FC<EventCalendarFiltersProps> = ({ dispatch, selectOptions, user }) => {
    const [modal, setModal] = useState(false);
    const T = Translator.create();
    const [, forceRender] = useReducer(x => x + 1, 0);
    const handleLanguageChange = useCallback(
        debounce(() => {
            forceRender(1);
        }, 1000),
        []
    );

    useEffect(() => {
        T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, handleLanguageChange);
        T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, handleLanguageChange);

        return () => {
            T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, handleLanguageChange);
        };
    }, []);


    const onFormSave = (model: Types.IFilterEvent, FormActions: FormikActions<Types.IFilterEvent>) => {

        const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
            if (result && result.code) {
                let errors: any = {};
                if (result.details) {
                    const validations: Array<Types.IValidationResponse> = result.details;
                    validations.forEach((m: Types.IValidationResponse) => {
                        errors[m.field] = m.message[0];
                    });
                }
                FormActions.setErrors(errors);
            }
        };

        // loads calendar events
        dispatch(Actions.ApiRequest(Constants.event_period.EVENT_PERIOD_SOLUTION_SEARCH, model, 'event-solution-spin', resultCallback));
        FormActions.setSubmitting(false);
    };

    useEffect(() => {
        dispatch(Actions.ApiRequest(Constants.event_period.EVENT_PERIOD_GET_SELECT_OPTIONS, {}, 'select-options'));
    }, [])

    const getEventTypeOptions = [
        ...(selectOptions && selectOptions.event_types || []),
        ...RBCEventTypes(T)
    ].sort((a, b) => a.label.localeCompare(b.label))
    const getCampusOptions = selectOptions && selectOptions.campuses ? selectOptions.campuses : [];
    const getBuildingOptions = selectOptions && selectOptions.buildings_related_campus ? selectOptions.buildings_related_campus : [];
    const getClassroomOptions = selectOptions && selectOptions.classrooms_related_building ? selectOptions.classrooms_related_building : [];
    const getUserOptions = selectOptions && selectOptions.users ? selectOptions.users : [];


    const isDisable = (values: Types.IFilterEvent, key: keyof Types.IFilterEvent): boolean => {
        const value = values[key];
        return !(value && Array.isArray(value) && value.length > 0);
    };

    const isValid = (option: any) => {
        return option && Array.isArray(option) && option.length !== 0;
    }

    return <>
        <Formik
            enableReinitialize={true}
            initialValues={initialFilter}
            onSubmit={(values, actions) => {
                onFormSave(values, actions);
            }}
        >
            {(props: FormikProps<Types.IFilterEvent>) => {


                const { errors, handleSubmit, resetForm, values, submitCount } = props;

                const isSearchActive = (values.campus_ids && values.campus_ids.length > 0) || (values.approval_statuses && values.approval_statuses.length > 0) || (values.event_type_ids && values.event_type_ids.length > 0) || (values.user_ids && values.user_ids.length > 0);
                return (
                    <form onSubmit={handleSubmit}>
                        <div className='row'>
                            <div className="col-md-3">
                                <Spinner name='select-options' />
                                <div className="mb-3 add-custom-tag">
                                    <div className="react-select-container">
                                        <label>{T.t('gen_campus')}</label>
                                        <Select
                                            key={JSON.stringify(values.campuses)}
                                            className="react-select"
                                            name="campuses"
                                            id="campuses"
                                            filterOption={(option, query) => option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))}
                                            isMulti={true}
                                            closeMenuOnSelect={false}
                                            options={getCampusOptions}
                                            placeholder={T.t('gen_select_campus')}
                                            value={props.values.campuses}
                                            onChange={(
                                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                            ) => {
                                                const list: Array<Types.ISelectOption> = options
                                                    ? (options as Array<Types.ISelectOption>)
                                                    : [];
                                                props.setFieldValue('campuses', list);
                                                props.setFieldValue(
                                                    'campus_ids',
                                                    list.map((item) => item.value)
                                                );
                                                props.setFieldValue('buildings', []);
                                                props.setFieldValue('building_ids', []);
                                                props.setFieldValue('classrooms', []);
                                                props.setFieldValue('classroom_ids', []);
                                                if (isValid(options))
                                                    dispatch(Actions.ApiRequest(Constants.event_period.EVENT_PERIOD_GET_SELECT_OPTIONS, { campus_ids: list.map((item) => item.value) }, 'select-options'));
                                            }}
                                            noOptionsMessage={(): string => T.t('gen_no_options_available')}
                                        />
                                    </div>
                                    {errors && errors.campuses && props.submitCount > 0 && (
                                        <div className="error">{errors && errors.campuses}</div>
                                    )}
                                </div>
                            </div>

                            <div className="col-md-3">
                                <Spinner name='select-options' />
                                <div className="mb-3 add-custom-tag">
                                    <div className="react-select-container">
                                        <label>{T.t('gen_building')}</label>
                                        <Select
                                            key={JSON.stringify(values.buildings)}
                                            className="react-select"
                                            name="building"
                                            id="building"
                                            isDisabled={isDisable(values, 'campuses')}
                                            filterOption={(option, query) =>
                                                option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                            }
                                            isMulti={true}
                                            closeMenuOnSelect={false}
                                            options={getBuildingOptions}
                                            placeholder={T.t('gen_select_building')}
                                            value={props.values.buildings}
                                            onChange={(
                                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                            ) => {
                                                const list: Array<Types.ISelectOption> = options
                                                    ? (options as Array<Types.ISelectOption>)
                                                    : [];
                                                props.setFieldValue('buildings', list);
                                                props.setFieldValue(
                                                    'building_ids',
                                                    list.map((item) => item.value)
                                                );
                                                props.setFieldValue('classrooms', []);
                                                props.setFieldValue('classroom_ids', []);
                                                if (isValid(options))
                                                    dispatch(Actions.ApiRequest(Constants.event_period.EVENT_PERIOD_GET_SELECT_OPTIONS, { building_ids: list.map((item) => item.value) }, 'select-options'));
                                            }}
                                            noOptionsMessage={(): string => T.t('gen_no_options_available')}
                                        />
                                    </div>
                                    {errors && errors.buildings && props.submitCount > 0 && (
                                        <div className="error">{errors && errors.buildings}</div>
                                    )}
                                </div>

                            </div>

                            <div className="col-md-3">
                                <Spinner name='select-options' />
                                <div className="mb-3 add-custom-tag">
                                    <div className="react-select-container">
                                        <label>{T.t('gen_classroom')}</label>
                                        <Select
                                            key={JSON.stringify(values.classrooms)}
                                            className="react-select"
                                            name="classroom/room"
                                            id="classroom/room"
                                            isDisabled={isDisable(values, 'buildings')}
                                            filterOption={(option, query) =>
                                                option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                            }
                                            isMulti={true}
                                            closeMenuOnSelect={false}
                                            options={getClassroomOptions}
                                            placeholder={T.t('gen_select_classroom')}
                                            value={props.values.classrooms}
                                            onChange={(
                                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                            ) => {
                                                const list: Array<Types.ISelectOption> = options
                                                    ? (options as Array<Types.ISelectOption>)
                                                    : [];
                                                props.setFieldValue('classrooms', list);
                                                props.setFieldValue(
                                                    'classroom_ids',
                                                    list.map((item) => item.value)
                                                );
                                            }}
                                            noOptionsMessage={(): string => T.t('gen_no_options_available')}
                                        />
                                    </div>
                                    {errors && errors.classrooms && props.submitCount > 0 && (
                                        <div className="error">{errors && errors.classrooms}</div>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-3">
                                <Spinner name='users-select-options' />
                                <div className="mb-3 add-custom-tag">
                                    <div className="react-select-container">
                                        <label>{T.t('gen_responsibles')}</label>
                                        <Select
                                            key={JSON.stringify(values.users)}
                                            className="react-select"
                                            name="users"
                                            id="users"
                                            filterOption={(option, query) =>
                                                option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                            }
                                            isMulti={true}
                                            closeMenuOnSelect={false}
                                            options={getUserOptions}
                                            placeholder={T.t('gen_select_user')}
                                            value={values.users}
                                            onChange={(
                                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                            ) => {
                                                const list: Array<Types.ISelectOption> = options
                                                    ? (options as Array<Types.ISelectOption>)
                                                    : [];
                                                props.setFieldValue('users', list);
                                                props.setFieldValue(
                                                    'user_ids',
                                                    list.map((item) => item.value)
                                                );
                                            }}
                                            noOptionsMessage={(): string => T.t('gen_no_options_available')}
                                        />
                                    </div>
                                    {errors && errors.users && submitCount > 0 && (
                                        <div className="error">{errors && errors.users}</div>
                                    )}
                                </div>
                            </div>

                            <div className="col-md-3">
                                <Spinner name='event-type-select-options' />
                                <div className="mb-3 add-custom-tag">
                                    <div className="react-select-container">
                                        <label>{T.t('gen_event_type')}</label>
                                        <Select
                                            key={JSON.stringify(values.event_types)}
                                            className="react-select"
                                            name="event_type"
                                            id="event_type"
                                            isMulti={true}
                                            closeMenuOnSelect={false}
                                            filterOption={(option, query) =>
                                                option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                            }
                                            options={getEventTypeOptions}
                                            placeholder={T.t('gen_select_event_type')}
                                            value={values.event_types}
                                            onChange={(
                                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                            ) => {
                                                const list: Array<Types.ISelectOption> = options
                                                    ? (options as Array<Types.ISelectOption>)
                                                    : [];
                                                props.setFieldValue('event_types', list);
                                                props.setFieldValue(
                                                    'event_type_ids',
                                                    list.map((item) => item.value)
                                                );
                                            }}
                                            noOptionsMessage={(): string => T.t('gen_no_options_available')}
                                        />
                                    </div>
                                    {errors && errors.event_types && submitCount > 0 && (
                                        <div className="error">{errors && errors.event_types}</div>
                                    )}
                                </div>
                            </div>

                            <div className="col-md-3">
                                <Spinner name='event-type-select-options' />
                                <div className="mb-3 add-custom-tag">
                                    <div className="react-select-container">
                                        <label>{T.t('gen_approval_status')}</label>
                                        <Select
                                            key={JSON.stringify(values.approval_status_options)}
                                            className="react-select"
                                            name="approval_status"
                                            id="approval_status"
                                            isMulti={true}
                                            closeMenuOnSelect={false}
                                            filterOption={(option, query) => {
                                                const label = option.label ? option.label.toLocaleLowerCase(T.getSelectedLanguage()) : '';
                                                const normalizedQuery = query.toLocaleLowerCase(T.getSelectedLanguage());
                                                return label.includes(normalizedQuery);
                                            }}
                                            options={Object.keys(RBCEventStatus)
                                                .filter(key => isNaN(Number(key))) // sadece string olanları alıyoruz (PENDING, APPROVED, DENIED)
                                                .map((key) => {
                                                    const numericValue = RBCEventStatus[key as keyof typeof RBCEventStatus];
                                                    return {
                                                        value: numericValue,
                                                        label: (RBCEventStatuses(T) as { [key: number]: any })[numericValue]
                                                    };
                                                })}
                                            placeholder={T.t('gen_select_approval_status')}
                                            value={values.approval_status_options}
                                            onChange={(
                                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                            ) => {
                                                const list: Array<Types.ISelectOption> = options
                                                    ? (options as Array<Types.ISelectOption>)
                                                    : [];
                                                props.setFieldValue('approval_status_options', list);
                                                props.setFieldValue(
                                                    'approval_statuses',
                                                    list.map((item) => item.value)
                                                );
                                            }}
                                            noOptionsMessage={(): string => T.t('gen_no_options_available')}
                                        />
                                    </div>
                                    {errors && errors.approval_status_options && submitCount > 0 && (
                                        <div className="error">{errors && errors.approval_status_options}</div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-12 text-right">
                            <div className='tw-flex tw-flex-row tw-items-center tw-space-x-2'>
                                {isAuthorized(user) && <Button color='green' icon='add' size='md' onClick={() => setModal(true)}>
                                    {T.t("gen_add_event")}
                                </Button>}
                                <div className='tw-flex-grow' />
                                <Button onClick={() => resetForm()} color='red' className="mt-md-0 mt-2 mb-md-0 mb-2">
                                    {T.t("reset")}
                                </Button>
                                <Button className='mt-md-0 mt-2 mb-md-0 mb-2' disabled={!isSearchActive} color='blue' icon='search' onClick={() => handleSubmit()}>
                                    {T.t("gen_search")}
                                </Button>
                            </div>
                        </div>
                        <hr />
                    </form>
                )
            }}
        </Formik>
        <AddEventPeriod isOpen={modal} toggle={() => setModal(!modal)} />
    </>
}

const mapStateToProps = (
    store: Types.IPersistedState,
    ownProps: EventCalendarFiltersProps
): EventCalendarFiltersProps => {
    if (!store) {
        return ownProps;
    }
    const newProps: EventCalendarFiltersProps = {
        selectOptions: store.state.select_options && store.state.select_options.solutionEventPage,
        user: store.state.user,
        ...ownProps,
    };
    return newProps;
};

const dispatchProps = (dispatch: any) => ({
    dispatch
});

const EventCalendarFilters = connect(mapStateToProps, dispatchProps)(EventCalendarFiltersIn);

export default EventCalendarFilters
