import * as React from 'react';
import { FormikProps } from "formik"
import Button from "../../components/button"
import TableBase from "../../components/table/Table"
import { IExtendedEvent } from "../../store/types"
import { TableType } from "../sketches/tools/interfaces"
import { IAvailablePlace } from "./constants"
import { Log } from 'ng2-logger';
import Translator from "../../services/translate-factory"
import { ClassroomFeatureOptions, ClassroomSeatingArrangementOptions, ClassroomSeatingTypeOptions } from '../../store/constants/classroom-const';

const L = Log.create('eventCalendarAddPlaceTable');

const AvailablePlaceTable: React.FC<{ formikProps: FormikProps<IExtendedEvent> }> = ({ formikProps: props }) => {
    const T = new Translator()
    const { values, setFieldValue } = props
    return <TableBase
        type={TableType.AVALIABLE_EVENT}
        spinner="available-place-table"
        disableFetchPagination={true}
        disableFetch={!props.values.isSearched}
        notFoundMessage={props.values.isSearched ? T.t("gen_no_data_found") : T.t("gen_select_campus_before_search")}
        columnComponent={[
            {
                widthPercentage: 15,
                __componentType: 'oneLine',
                __headerType: 'default',
                title: T.t("gen_name"),
                parseName: "name",
            },
            {
                widthPercentage: 15,
                __componentType: 'oneLine',
                parseName: '',
                __headerType: 'default',
                title: T.t("gen_campus") + "/" + T.t("gen_building"),
                customRender: (record: IAvailablePlace) => {
                    return (
                        <td className="text-center">
                            <span>{record.campus_name}</span>
                            <span>/</span>
                            <span>{record.building_name}</span>
                        </td>
                    )
                }
            },
            {
                widthPercentage: 5,
                __componentType: 'oneLine',
                __headerType: 'default',
                title: T.t("gen_floor"),
                parseName: "building_floor",
            },
            {
                widthPercentage: 10,
                __componentType: 'oneLine',
                __headerType: 'default',
                title: T.t("gen_lecture_capacity"),
                parseName: "lecture_capacity",
            },
            {
                widthPercentage: 10,
                __componentType: 'oneLine',
                __headerType: 'default',
                title: T.t("gen_exam_capacity"),
                parseName: "exam_capacity",
            },
            {
                widthPercentage: 10,
                __componentType: 'oneLine',
                __headerType: 'default',
                title: T.t("gen_sitting_type"),
                parseName: "classroom_seating_type",
                translateMap: ClassroomSeatingTypeOptions(T)
            },
            {
                widthPercentage: 10,
                __componentType: 'oneLine',
                __headerType: 'default',
                title: T.t("gen_seating_arrangement"),
                parseName: "classroom_seating_arrangement",
                translateMap: ClassroomSeatingArrangementOptions(T)
            },
            {
                widthPercentage: 10,
                __componentType: 'multiLine',
                __headerType: 'default',
                title: T.t("gen_feature"),
                parseName: "feature_codes",
                translateMap: ClassroomFeatureOptions(T)
            },
            {
                widthPercentage: 10,
                __componentType: 'oneLine',
                __headerType: 'default',
                parseName: '',
                customRender: (record: any) => {
                    const isSelected = values.selectedPlace && values.selectedPlace.id === record.id

                    return (
                        <td className="text-center tw-w-[128px]">
                            <Button color={
                                isSelected ? "green" : "genericBlue"
                            } onClick={() => !isSelected ? setFieldValue('selectedPlace', record) : setFieldValue('selectedPlace', undefined)}>
                                {isSelected ? T.t('gen_selected') : T.t('gen_select')}
                            </Button>
                        </td>
                    )
                }
            }
        ]}
    />
}

export default AvailablePlaceTable