import * as Types from '../types';
import moment from 'moment';
import { momentLocalizer } from 'react-big-calendar';
import Translator from '../../services/translate-factory';

const localizer = momentLocalizer(moment);

export const constants = {
  COURSE_INDEX: 'SOLUTION:INDEX',
  SOLUTION_GET_BY_TERM: 'SOLUTION:GET_BY_TERM',
  SOLUTION_LIST_SEARCH: 'SOLUTION:LIST_SEARCH',
  SOLUTION_LIST_INVIGILATOR_COUNTS: 'SOLUTION:LIST_INVIGILATOR_COUNTS',
  SOLUTION_GET_SINGLE_TABLES: 'SOLUTION:GET_SINGLE_TABLES',
  SOLUTION_GET_PUBLIC_TABLES: 'SOLUTION:GET_PUBLIC_TABLES',
  COURSE_SOLUTION_PUBLIC: 'COURSE_SOLUTION:PUBLIC',
  COURSE_SOLUTION_GET_SINGLE_TABLES: 'COURSE_SOLUTION:GET_SINGLE_TABLES',
  SOLUTION_GET_BY_PROGRAMS: 'SOLUTION:GET_BY_PROGRAMS',
  SOLUTION_GET_BY_INSTRUCTORS: 'SOLUTION:GET_BY_INSTRUCTORS',
  SOLUTION_GET_BY_CLASSROOMS: 'SOLUTION:GET_BY_CLASSROOMS',
  SOLUTION_GET_BY_STUDENTS: 'SOLUTION:GET_BY_STUDENTS',
  SOLUTION_GET_BY_INVIGILATORS: 'SOLUTION:GET_BY_INVIGILATORS',
  SOLUTION_GET_BY_PROGRAMS_EX_INVIGILATORS: 'SOLUTION:GET_BY_PROGRAMS_EX_INVIGILATORS',
  SOLUTION_GET_INVIGILATION_COUNTS: 'SOLUTION:GET_INVIGILATION_COUNTS',
  SOLUTION_PUBLISH_SOLUTION: 'SOLUTION_PUBLISH_SOLUTION',
  SOLUTION_COPY_SOLUTION: 'SOLUTION:SOLUTION_COPY_SOLUTION',
  SOLUTION_DELETE_SOLUTION: 'SOLUTION:SOLUTION_DELETE_SOLUTION',
  SOLUTION_ACTIVITY_SELECT_OPTIONS: 'SOLUTION:SOLUTION_ACTIVITIY_SELECT_OPTIONS',
  SOLUTION_ACTIVITY_CLASSROOM_SELECT_OPTIONS: 'SOLUTION:SOLUTION_ACTIVITIY_CLASSROOM_SELECT_OPTIONS',
  SOLUTION_EDIT_ACTIVITY: 'SOLUTION:SOLUTION_EDIT_ACTIVITY',
  SOLUTION_ADD_ACTIVITY: 'SOLUTION:SOLUTION_ADD_ACTIVITY',
  SOLUTION_DELETE_ACTIVITY: 'SOLUTION:SOLUTION_DELETE_ACTIVITY',
  SOLUTION_GET_PROGRAMS_BY_FACULTIES: 'SOLUTION:GET_PROGRAMS_BY_FACULTIES',
  SOLUTION_COURSE_SELECT_OPTIONS: 'SOLUTION:SOLUTION_COURSE_SELECT_OPTIONS',
  SOLUTION_COURSE_GET_PROGRAMS_BY_FACULTIES: 'SOLUTION:SOLUTION_COURSE_GET_PROGRAMS_BY_FACULTIES',
  SOLUTION_EDIT_COURSE: 'SOLUTION:SOLUTION_EDIT_COURSE',
  SOLUTION_ADD_COURSE: 'SOLUTION:SOLUTION_ADD_COURSE',
  SOLUTION_DELETE_COURSE: 'SOLUTION:SOLUTION_DELETE_COURSE',
  STUDENT_DOWNLOAD_EXAM_LIST: 'STUDENT_DOWNLOAD_EXAM_LIST',
  INSTRUCTOR_DOWNLOAD_EXAM_LIST: "INSTRUCTOR_DOWNLOAD_EXAM_LIST",
  SOLUTION_ACTIVITY_LIST_SEARCH: 'SOLUTION:COURSE_LIST_SEARCH',
  SOLUTION_GET_ACTIVITY_PROGRAMS: 'SOLUTION:GET_ACTIVITY_PROGRAMS',
  SOLUTION_GET_COURSE_PROGRAMS: 'SOLUTION:GET_COURSE_PROGRAMS',
  SOLUTION_DOWNLOAD_RAW_FILES: 'SOLUTION_DOWNLOAD_RAW_FILES',
  SOLUTION_GET_EXAM_DATES: 'SOLUTION:GET_EXAM_DATES',
  SOLUTION_BATCH_CHANGE_EXAM_DATES: 'SOLUTION:BATCH_CHANGE_EXAM_DATES'
};

export const SolutionPageInitialValues: Types.ISolutionPageProps = {
  term_id: undefined,
  name: ''
};

export const SolutionFilterInitialValues: Types.IFilterSolutionModel = {
  term_id: undefined,
  course_id: undefined,
  solution_id: -1,
  active_tab: '0',
  query: '',
  page: 1,
  order_by: '',
  total: -1,
  size: 10,
  name: ''
};

export const ISolutionModelInitialValues: Types.ISolutionModel = {
  createDate: '',
  explanation: '',
  instructors: [],
  programs: [],
  invigilators: [],
  classrooms: [],
  solutionExams: [],
  solutionClassrooms: [],
  solutionInvigilators: [],
  solutionStudents: [],
  solutionList: []
};

export const ExamSolutionSearchInitialValues: Types.IFilterExamSolution = {
  term_id: undefined,
  instructors: [],
  faculties: [],
  programs: [],
  grades: [],
  sections: [],
  invigilators: [],
  classrooms: [],
  courses: [],
  instructor_ids: [],
  faculty_ids: [],
  program_ids: [],
  grade_ids: [],
  section_ids: [],
  invigilator_ids: [],
  classroom_ids: [],
  course_ids: [],
  student_number: '',
  unique_number: '',
  dates: [],
  date_ids: []
};

export const ISolutionGridInitialValues: Types.ISolutionGridState = {
  formikRef: undefined,
  filters: ExamSolutionSearchInitialValues,
  // necessary data to create calendar component
  scheduleData: {
    startDate: moment().toDate(),
    min: 8,
    max: 18,
    max_minute: 0,
    min_minute: 0,
    step: 15,
    slotDuration: 60,
    isScheduleCreated: false
  },
  // raw solution data comes from props
  dataModel: ISolutionModelInitialValues,
  // initial solution exam data. never modified.
  initialModel: [],
  // filtered version of the initial model.
  filteredModel: [],
  // converted version of the filtered model for the calendar component to show.
  resultModel: [],
  // selected option IDs to use in filterin operations
  selectOptions: {
    faculties: [],
    programs: [],
    grades: [],
    sections: [],
    classrooms: [],
    instructors: [],
    invigilators: [],
    courses: []
  },
  // select option objects (Types.ISelectOption) to control data in react-select components
  selected_Options: {
    faculties: [],
    programs: [],
    grades: [],
    sections: [],
    classrooms: [],
    instructors: [],
    invigilators: [],
    courses: []
  },
  // display selected event in modal
  eventModalIsOpen: false,
  reportsDownloadModalIsOpen: false,
  isSearchRequest: false,
  selectedEvent: {}
};

export const ActivitySolutionSearchInitialValues: Types.IFilterActivitySolution = {
  term_id: undefined,
  activities: [],
  activity_ids: [],
  instructors: [],
  instructor_ids: [],
  faculties: [],
  faculty_ids: [],
  programs: [],
  program_ids: [],
  sections: [],
  section_ids: [],
  classrooms: [],
  classroom_ids: [],
  weeks: [],
  holiday_exclusions: [],
  week: 0,
  holiday_exclusion: 0,
  packages: [],
  package_ids: []
};

export const ICourseSolutionGridInitialValues: any = {
  // necessary data to create calendar component
  scheduleData: {
    startDate: moment().toDate(),
    min: 8,
    max: 18,
    max_minute: 0,
    min_minute: 0,
    step: 15,
    slotDuration: 60,
    isScheduleCreated: false
  },
  // raw solution data comes from props
  dataModel: ISolutionModelInitialValues,
  // initial solution activity data. never modified.
  initialModel: [],
  // filtered version of the initial model.
  filteredModel: [],
  // converted version of the filtered model for the calendar component to show.
  resultModel: [],
  // filters to generate filteredModel
  defaultDate: undefined,
  filters: {
    programs: [],
    classrooms: [],
    instructors: [],
    weekSolution: [],
    holidayExclusionSolution: [],
    activitySolution: [],
    classroomSolution: [],
    instructorSolution: [],
    programSolution: [],
    facultySolution: [],
    branchSolution: [],
    packageSolution: []
  },
  // selected option IDs to use in filterin operations
  selectOptions: {
    programs: [],
    classrooms: [],
    instructors: [],
    weekSolution: [],
    holidayExclusionSolution: [],
    activitySolution: [],
    classroomSolution: [],
    instructorSolution: [],
    programSolution: [],
    facultySolution: [],
    branchSolution: [],
    packageSolution: []
  },
  // select option objects (Types.ISelectOption) to control data in react-select components
  selected_Options: {
    programs: [],
    classrooms: [],
    instructors: [],
    weekSolution: [],
    holidayExclusionSolution: [],
    activitySolution: [],
    classroomSolution: [],
    instructorSolution: [],
    programSolution: [],
    facultySolution: [],
    branchSolution: [],
    packageSolution: []
  },
  // display selected event in modal
  eventModalIsOpen: false,
  selectedEvent: {},
  activityCopyModalIsOpen: false,
  reportsDownloadModalIsOpen: false
};

export const examMessagesValues = (T: Translator) => ({
  date: T.t('gen_date'),
  time: T.t('gen_time'),
  event: T.t('gen_course_code') + " — " + T.t('gen_course_name') + " — " + T.t('gen_grade') + " — " + T.t('gen_section') + " — " + T.t('gen_instructor') + " — " + T.t('gen_classroom'),
  allDay: T.t('gen_all_day'),
  week: T.t('gen_week'),
  work_week: T.t('gen_work_week'),
  day: T.t('gen_day'),
  month: T.t('gen_month'),
  previous: T.t('gen_previous'),
  next: T.t('gen_next'),
  yesterday: T.t('gen_yesterday'),
  tomorrow: T.t('gen_tomorrow'),
  today: T.t('gen_today'),
  agenda: T.t('gen_agenda'),
  noEventsInRange: T.t('gen_no_events_range'),
  showMore: function (e: any) {
    return '+' + e + T.t('gen_exam');
  }
});

export const courseMessagesValues = (T: Translator) => ({
  date: T.t('gen_date'),
  time: T.t('gen_time'),
  event: T.t('gen_activity_code') + " — " + T.t('gen_activity_name') + " — " + T.t('gen_instructor') + " — " + T.t('gen_grade') + " — " + T.t('gen_section') + " — " + T.t('gen_campus') + " — " + T.t('gen_classroom'),
  allDay: T.t('gen_all_day'),
  week: T.t('gen_week'),
  work_week: T.t('gen_work_week'),
  day: T.t('gen_day'),
  month: T.t('gen_month'),
  previous: T.t('gen_previous'),
  next: T.t('gen_next'),
  yesterday: T.t('gen_yesterday'),
  tomorrow: T.t('gen_tomorrow'),
  today: T.t('gen_today'),
  agenda: T.t('gen_agenda'),
  noEventsInRange: T.t('gen_no_events_range'),
  showMore: function (e: any) {
    return '+' + e + T.t('gen_exam');
  }
});

export const formatsValues = (T: Translator) => ({
  dayFormat: 'D MMMM dddd',
  dateFormat: 'D',
  weekdayFormat: 'dddd',
  dayHeaderFormat: (date: any) => localizer.format(date, 'D MMMM dddd', moment.locale(T.getSelectedLanguage())),
  agendaDateFormat: (date: any) => localizer.format(date, 'D MMMM dddd', moment.locale(T.getSelectedLanguage())),
  dayRangeHeaderFormat: ({ start, end }: { start: Date, end: Date }) => {
    const startFormatted = localizer.format(start, 'D MMMM', moment.locale(T.getSelectedLanguage()));
    const endFormatted = localizer.format(end, 'D MMMM', moment.locale(T.getSelectedLanguage()));

    return `${startFormatted} — ${endFormatted}`;
  },
  selectRangeFormat: (date: any) => localizer.format(date, 'h m', moment.locale(T.getSelectedLanguage())),
  agendaHeaderFormat: (date: any) =>
    localizer.format(date.start, 'D MMMM YYYY', moment.locale(T.getSelectedLanguage())) +
    ' — ' +
    localizer.format(date.end, 'D MMMM YYYY', moment.locale(T.getSelectedLanguage()))
});

export const coursesFormatsValues = (T: Translator, weekDateFormat?: string) => ({
  dayFormat: 'dddd',
  dateFormat: 'D',
  weekdayFormat: 'dddd',
  selectRangeFormat: (date: any) => localizer.format(date, 'h m', moment.locale(T.getSelectedLanguage())),
  // For Week View
  weekSelectBoxFormat: (date: any) => localizer.format(date, 'D MMMM', moment.locale(T.getSelectedLanguage())),
  dayHeaderFormat: (date: any) => localizer.format(date, 'D MMMM dddd', moment.locale(T.getSelectedLanguage())),
  dayRangeHeaderFormat: ({ start, end }: { start: Date, end: Date }) => {
    const startFormatted = localizer.format(start, 'D MMMM', moment.locale(T.getSelectedLanguage()));
    const endFormatted = localizer.format(end, 'D MMMM', moment.locale(T.getSelectedLanguage()));

    return `${startFormatted} — ${endFormatted}`;
  },
  // For Agenda View
  agendaDateFormat: (date: any) => localizer.format(date, 'D MMMM dddd', moment.locale(T.getSelectedLanguage())),
  agendaHeaderFormat: (date: any) =>
    localizer.format(date.start, 'D MMMM YYYY', moment.locale(T.getSelectedLanguage())) +
    ' — ' +
    localizer.format(date.end, 'D MMMM YYYY', moment.locale(T.getSelectedLanguage()))
});

export const SolutionEditModalInitialValues: Types.IFilterSolutionEditModal = {
  selected_types: []
};

export const calendarFormatsValues = (T: Translator) => ({
  dayFormat: 'dddd',
  dateFormat: 'D',
  weekdayFormat: 'dddd',
  selectRangeFormat: (date: any) => localizer.format(date, 'h m', moment.locale(T.getSelectedLanguage())),
  // For Week View
  weekSelectBoxFormat: (date: any) => localizer.format(date, 'D MMMM', moment.locale(T.getSelectedLanguage())),
  dayHeaderFormat: (date: any) => localizer.format(date, 'D MMMM dddd', moment.locale(T.getSelectedLanguage())),
  dayRangeHeaderFormat: ({ start, end }: { start: Date, end: Date }) => {
    const startFormatted = localizer.format(start, 'D MMMM', moment.locale(T.getSelectedLanguage()));
    const endFormatted = localizer.format(end, 'D MMMM', moment.locale(T.getSelectedLanguage()));

    return `${startFormatted} — ${endFormatted}`;
  },
  // For Agenda View
  agendaDateFormat: (date: any) => localizer.format(date, 'D MMMM dddd', moment.locale(T.getSelectedLanguage())),
  agendaHeaderFormat: (date: any) =>
    localizer.format(date.start, 'D MMMM YYYY', moment.locale(T.getSelectedLanguage())) +
    ' — ' +
    localizer.format(date.end, 'D MMMM YYYY', moment.locale(T.getSelectedLanguage()))
});