import { Modal, StepProps, Steps } from "antd";
import React, { useState, useRef } from "react";
import Button from "../../components/button";
import cn, { flexIC, flexRow, flexCenter, flexCol, gap2 } from "../../components/ui/Tailwind";
import Translator from '../../services/translate-factory';
import { Formik, FormikActions, FormikProps } from 'formik';
import { Log } from 'ng2-logger';
import { IAppSelectOptions, IAuthUser, IExtendedEvent, IPersistedState } from "../../store/types";
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import Preview, { isAuthorized } from "./add-preview";
import AvailablePlace from "./add-place";
import EventDetail from "./add-detail";
import { connect } from "react-redux";
import { RBCEventStatus } from "./constants";
import moment from "moment";
import { EventFormValidation, initialEventFormState } from "./validations/form-validation";

const L = Log.create('eventCalendarAdd');

interface AddEventPeriodProps {
    isOpen: boolean
    toggle: () => void,
    dispatch?: any,
    selectOptions?: IAppSelectOptions;
    user?: IAuthUser
    term_id?: number
}

interface StepItem {
    key: string
    content: React.ReactNode
    item: StepProps
}

export const AddEventPeriodIn: React.FC<AddEventPeriodProps> = ({ term_id, isOpen, toggle, dispatch, selectOptions, user }) => {
    const T = Translator.create()
    const [current, setCurrent] = useState(0);
    const formikRef = useRef<Formik<IExtendedEvent>>(null)

    const next = () => {
        setCurrent(current + 1)
    }
    const prev = () => {
        setCurrent(current - 1)
    }

    const stepItems = (formikProps: FormikProps<IExtendedEvent>): StepItem[] => [
        {
            key: 'event_info',
            content: <EventDetail formikProps={formikProps} />,
            item: {
                title: T.t("gen_event_info"),
            },
        },
        {
            key: 'place_info',
            content: <AvailablePlace formikProps={formikProps} />,
            item: {
                title: T.t('gen_select_place'),
            },
        },
        {
            key: 'preview',
            content: <Preview formikProps={formikProps} user={user} />,
            item: {
                title: T.t("gen_preview"),
            },

        }
    ]

    const searchEventCalendar = () => {
        // loads calendar events
        dispatch(Actions.ApiRequest(Constants.event_period.EVENT_PERIOD_SOLUTION_SEARCH, { filter: { term_id: term_id } }, 'event-solution-spin'));
    }
    const onSubmit = (values: IExtendedEvent, formikActions: FormikActions<IExtendedEvent>) => {
        setSubmitting(true)
        if (!values.selectedPlace || !values.event_type)
            return formikActions.setErrors({ event_type: T.t('gen_cannot_leave_empty'), selectedPlace: T.t('gen_cannot_leave_empty') })

        dispatch(Actions.ApiRequest(Constants.event_period.EVENT_PERIOD_EVENT_CREATE, {
            ...values,
            classroom_id: values.selectedPlace.id,
            title: values.title,
            description: values.description,
            event_type_id: values.event_type.value,
            user_ids: values.responsibles != undefined ? values.responsibles.map((item) => item.value) : [],
            start_date: moment(values.start_date).format("DD.MM.YYYY HH:mm"),
            end_date: moment(values.end_date).format("DD.MM.YYYY HH:mm"),
            status_approval: values.status_approval
        }, 'submit-save-event', () => {
            setSubmitting(false)
            searchEventCalendar()
            // close modal after 1 second
            const timer = setTimeout(() => {
                toggle()
            }, 1000)
            return () => clearTimeout(timer)
        }));
    }

    const [submitting, setSubmitting] = useState(false)
    return (
        <Modal
            className="pt-0 tw-duration-150 tw-transform tw-translate-y-0"
            style={{ maxWidth: '92rem', padding: '0 15px' }}
            open={isOpen}
            onCancel={() => {
                toggle()
            }}
            afterClose={() => {
                setCurrent(0)
                if (formikRef.current)
                    formikRef.current.resetForm()
            }}
            width="100%"
            title={T.t('gen_add_event')}
            okButtonProps={{ style: { display: 'none' } }}
            cancelButtonProps={{ style: { display: 'none' } }}
        >
            <Formik
                initialValues={{
                    ...initialEventFormState,
                    status_approval: isAuthorized(user)
                        ? RBCEventStatus.APPROVED
                        : RBCEventStatus.PENDING,

                }}
                validationSchema={EventFormValidation(T)}
                onSubmit={onSubmit}
                ref={formikRef}
            >
                {(formikProps: FormikProps<IExtendedEvent>) => {
                    const { values, handleSubmit } = formikProps
                    const isDateSelected = values.start_date && values.end_date && values.event_type && values.title
                    const disableNext = (current: number) => {
                        switch (current) {
                            case 0:
                                return current === 0 && !isDateSelected
                            case 1:
                                return current === 1 && !values.selectedPlace
                            default:
                                return false
                        }
                    }
                    const isPreview = current === stepItems(formikProps).length - 1
                    return <form>
                        <section className={cn(flexCol, gap2, flexIC)}>
                            <Steps
                                direction="horizontal"
                                // className="tw-max-w-[256px] tw-w-full"
                                progressDot={true}
                                current={current}
                                items={
                                    stepItems(formikProps).map(item => item.item)
                                } />
                            {/* content */}
                            <div className={cn(flexCenter, "tw-min-h-[425px] tw-bg-gray-100 tw-rounded-lg my-4 tw-p-4 tw-w-full tw-flex-grow")}>
                                {stepItems(formikProps)[current].content}
                            </div>
                        </section>
                        {/* end buttons */}
                        <div className={cn(flexRow, flexIC, "tw-justify-end tw-space-x-2")}>
                            <Button size="md" borderColor="light-gray" onClick={current === 0 ? toggle : prev}>
                                {current === 0 ? T.t("gen_cancel") : T.t("gen_previous")}
                            </Button>

                            <Button
                                size="md"
                                icon={isPreview ? "save" : undefined}
                                disabled={disableNext(current) || submitting}
                                color={isPreview ? "btnGreen" : "blue"} onClick={isPreview ? () => handleSubmit() : next}
                            >
                                {isPreview ? T.t("gen_save") : T.t("gen_next")}
                            </Button>
                        </div>
                    </form>
                }}
            </Formik>
        </Modal >
    )
}


const mapStateToProps = (store: IPersistedState, ownProps: AddEventPeriodProps) => ({
    ...ownProps,
    selectOptions: store.state.select_options,
    user: store.state.user,
    term_id: store.state.term_id
});

const dispatchProps = (dispatch: any) => ({
    dispatch
});

const AddEventPeriod = connect(mapStateToProps, dispatchProps)(AddEventPeriodIn);


export default AddEventPeriod