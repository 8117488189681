import Translator from "../../services/translate-factory";
import fallbackImage1 from '../../assets/images/default-fallback-image.png';
import fallbackImage2 from '../../assets/images/fallback.jpg';

export { fallbackImage1, fallbackImage2 }

export enum RBCEventType {
    COURSE = 1,
    EXAM = 2,
    OTHER = 3,
}

export const RBCEventTypes = (T: Translator) => ([
    // { label: T.t('gen_midterm_makeup_exam'), value: -5 },
    // { label: T.t('gen_final_makeup_exam'), value: -4 },
    // { label: T.t('gen_makeup_exam'), value: -3 },
    // { label: T.t('gen_seminar'), value: -2 },
    // { label: T.t('gen_meeting'), value: -1 },
    { label: T.t('gen_event'), value: RBCEventType.OTHER },
    // { label: T.t('gen_exam'), value: RBCEventType.EXAM },
    // { label: T.t('gen_course'), value: RBCEventType.COURSE },
]).sort((a, b) => a.label.localeCompare(b.label))

export enum RBCEventStatus {
    PENDING = 1,
    APPROVED = 2,
    DENIED = 3,
}

export const RBCEventStatuses = (T: Translator) => ({
    [RBCEventStatus.PENDING]: T.t('gen_pending'),
    [RBCEventStatus.APPROVED]: T.t('gen_approved'),
    [RBCEventStatus.DENIED]: T.t('gen_rejected'),
})


export const calendarMessages = (T: Translator) => ({
    date: T.t('gen_date'),
    time: T.t('gen_time'),
    event: T.t('gen_event'),
    allDay: T.t('gen_all_day'),
    week: T.t('gen_week'),
    work_week: T.t('gen_work_week'),
    day: T.t('gen_day'),
    month: T.t('gen_month'),
    previous: T.t('gen_previous'),
    next: T.t('gen_next'),
    yesterday: T.t('gen_yesterday'),
    tomorrow: T.t('gen_tomorrow'),
    today: T.t('gen_today'),
    agenda: T.t('gen_agenda'),
    noEventsInRange: T.t('gen_no_events_range'),
    showMore: function (e: any) {
        return '+' + e + T.t('gen_events');
    }
});

export interface IAvailablePlace {
    id: number; //classroom/room id
    name: string;
    campus_name: string;
    building_name: string;
    floor: string;
    lecture_capacity: number;
    exam_capacity: number;
    seating_type: number;
    seating_arrangement: number;
    feature: string[];
}

