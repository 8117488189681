import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { Calendar, EventProps, momentLocalizer } from "react-big-calendar";
import moment from 'moment';
import { connect } from 'react-redux';
import { Tooltip } from 'antd';
import Translator from '../../services/translate-factory';
import EventCalendarFilters from './event-calendar-filters';
import * as Types from '../../store/types';
import { calendarMessages, RBCEventStatus, RBCEventType, RBCEventTypes } from './constants';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import cn, { flexIC, flexRow, gap1 } from '../../components/ui/Tailwind';
import { truncateString } from '../../util/truncate';
import { debounce } from 'lodash';
import EventCalendarDetail from './event-calendar-detail';
import { Log } from 'ng2-logger';
import { TermType } from '../../store/constants/general';
import { getLocalizedDateLocalizer } from '../../util/language';
import { calendarFormatsValues } from '../../store/constants/solution-const';


const L = Log.create('event-calendar.tsx');
const T = Translator.create();
interface EventCalendarProps {
    event_page?: any;
    dispatch?: any;
    term_id?: number;
    term_type?: number;
}

const EventCalendarIn: React.FC<EventCalendarProps> = ({ event_page, dispatch, term_id, term_type }) => {
    const [open, setOpen] = useState(false)
    const [event, setEvent] = useState<Types.RBCEvents>()

    const [, forceRender] = useReducer(x => x + 1, 0);

    const localizer = getLocalizedDateLocalizer(T)

    const handleLanguageChange = useCallback(
        debounce(() => {
            forceRender(1);
        }, 1000),
        []
    );

    useEffect(() => {
        eventCalendarSearch()
    }, [])

    const eventCalendarSearch = () => {
        // loads calendar events
        dispatch(Actions.ApiRequest(Constants.event_period.EVENT_PERIOD_SOLUTION_SEARCH, { filter: { term_id: term_id } }, 'event-solution-spin'));
        dispatch(Actions.ApiRequest(Constants.campus.CAMPUS_GET_SELECT_OPTIONS, undefined, 'event-type-options'));
    }

    useEffect(() => {
        T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, handleLanguageChange);
        T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, handleLanguageChange);

        return () => {
            T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, handleLanguageChange);
        };
    }, []);

    const onCloseDetail = () => {
        setEvent(undefined)
    }

    useEffect(() => {
        if (term_type !== TermType.EVENT) {
            dispatch(Actions.Navigation('/event-period'))
        }
        if (event) {
            setOpen(true)
        } else {
            setOpen(false)
        }
    }, [event])


    let _events: Types.RBCEvents[] = event_page && event_page.data;

    return (
        <div className="main editor-screen-main" style={{ display: 'block', marginTop: '8px' }}>
            <div
                className="white-container mt-4 editor-screen collapse editor-screen"
                style={{ display: 'block', minHeight: '100vh' }}
            >
                <div>
                    <h5>{T.t("gen_event_calendar")}</h5>
                    <EventCalendarFilters />
                    <EventCalendarDetail event={event} isOpen={open} onClose={() => { setOpen(false) }} afterClose={onCloseDetail} key={event && event.event_id} />
                    <div className="generic-wrapper" style={{ height: '100vh', borderRadius: '12px' }}>
                        <Calendar
                            localizer={localizer}
                            startAccessor="start_date"
                            endAccessor="end_date"
                            defaultDate={moment().toDate()}
                            messages={calendarMessages(T)}
                            formats={calendarFormatsValues(T)}
                            showMultiDayTimes
                            events={_events != undefined ? _events.map(_ => ({
                                ..._,
                                start_date: moment(_.start_date).toDate(),
                                end_date: moment(_.end_date).toDate(),
                            })) : []}
                            views={['month', 'week', 'day', 'agenda',]}
                            defaultView="week"
                            tooltipAccessor={(event) => {
                                return ""
                            }}
                            onSelectEvent={(event) => {
                                setEvent(event)
                            }}
                            eventPropGetter={(event, start_date, end_date, isSelected) => {
                                /**
                                 * Represents the style object for the event calendar.
                                 */
                                let newStyle: React.CSSProperties = {
                                    borderRadius: '0px',
                                };
                                let className
                                switch (event.event_type && event.event_type.value) {
                                    case RBCEventType.EXAM:
                                        className = "tw-text-red-500 tw-bg-red-100 tw-border-solid tw-border tw-border-red-500 tw-rounded-lg hover:tw-z-50"
                                        break;
                                    case RBCEventType.COURSE:
                                        className = "tw-text-blue-500 tw-bg-blue-100 tw-border-solid tw-border tw-border-blue-500 tw-rounded-lg hover:tw-z-50"
                                        break;
                                    case RBCEventType.OTHER:
                                        className = "tw-text-gray-500 tw-bg-gray-100 tw-border-solid tw-border tw-border-gray-500 tw-rounded-lg hover:tw-z-[1000] tw-z-50"
                                        break;

                                    default:
                                        className = "tw-text-gray-500 tw-bg-gray-100 tw-border-solid tw-border tw-border-gray-500 tw-rounded-lg hover:tw-z-[1000] tw-z-50"
                                        break;
                                }

                                return {
                                    className: className + " tw-overflow-auto",
                                    style: newStyle,
                                    title: undefined,
                                };
                            }}
                            components={{
                                event: (e) => {
                                    let tooltip = undefined
                                    switch (e.event.status_approval) {
                                        case RBCEventStatus.PENDING:
                                            tooltip = T.t("gen_waiting_action")
                                            break;
                                        case RBCEventStatus.APPROVED:
                                            tooltip = T.t("gen_approved")
                                            break;
                                        case RBCEventStatus.DENIED:
                                            tooltip = T.t("gen_denied")
                                            break;

                                        default:
                                            break;
                                    }
                                    return (
                                        <Tooltip placement='topRight' title={tooltip}>
                                            <div>
                                                <div className='tw-flex tw-flex-col'>
                                                    <div className='tw-flex tw-flex-row tw-justify-between'>

                                                        <span>{getHour(e.event)}</span>
                                                        {getToolTip(e)}
                                                    </div>
                                                    <h5 className='tw-text-base tw-leading-5'>
                                                        {e.title}
                                                    </h5>
                                                </div>
                                                <div className='tw-text-xs'>{e.event.description}</div>
                                                {
                                                    e.event.campus &&
                                                    <>
                                                        <hr style={{ margin: '2px' }} />
                                                        <div className='tw-text-xs'>{e.event.campus.label}</div>
                                                    </>
                                                }
                                                {
                                                    e.event.building &&
                                                    <>
                                                        <hr style={{ margin: '2px' }} />
                                                        <div className='tw-text-xs'>{e.event.building.label}</div>
                                                    </>
                                                }
                                                {
                                                    e.event.classroom &&
                                                    <>
                                                        <hr style={{ margin: '2px' }} />
                                                        <div className='tw-text-xs'>{e.event.classroom.label}</div>
                                                    </>
                                                }
                                                {
                                                    e.event.responsibles &&
                                                    <>
                                                        <hr style={{ margin: '2px' }} />
                                                        {
                                                            e.event.responsibles.map((r, i) => (
                                                                <div key={r.value} className='tw-text-xs'>{r.label}</div>
                                                            ))
                                                        }
                                                    </>
                                                }
                                            </div>
                                        </Tooltip>
                                    )
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

const getHour = (event: Types.RBCEvents) => {
    let startDate = new Date(event.start_date);
    let endDate = new Date(event.end_date);
    return `${startDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} - ${endDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`
}

const getToolTip = (event: React.PropsWithChildren<EventProps<Types.RBCEvents>>) => {
    let className = 'tw-text-xs tw-w-2 tw-h-2 tw-rounded-full '
    switch (event.event.status_approval) {
        case RBCEventStatus.PENDING:
            className += "tw-bg-yellow-500"
            break;
        case RBCEventStatus.APPROVED:
            className += "tw-bg-green-500"
            break;
        case RBCEventStatus.DENIED:
            className += "tw-bg-red-500"
            break;

        default:
            className = ""
            break;
    }

    const type = event.event.event_type

    const localized = RBCEventTypes(T).find(_ => type && (_.value === type.value))

    if (localized && type)
        type.label = localized.label

    return <div className={cn('', flexRow, flexIC, gap1)}>
        {type && <div className={cn(
            {
                'tw-bg-red-200': type.value === RBCEventType.EXAM,
                'tw-bg-blue-200': type.value === RBCEventType.COURSE,
                'tw-bg-gray-200': type.value >= RBCEventType.OTHER
            },
            'tw-rounded-lg tw-px-2 tw-py-0.5')} title={type.label}>{truncateString(type.label, 16)}</div>}
        <div className={className} />
    </div>

}

const mapStateToProps = (
    store: Types.IPersistedState,
    ownProps: EventCalendarProps
): EventCalendarProps => {
    if (!store) {
        return ownProps;
    }
    const newProps: EventCalendarProps = {
        event_page: store.state.event_period_page && store.state.event_period_page.solutionPage,
        term_id: store.state.term_id,
        term_type: store.state.term_type,
        ...ownProps,
    };
    return newProps;
};

const dispatchProps = (dispatch: any) => ({
    dispatch
});

const EventCalendar = connect(mapStateToProps, dispatchProps)(EventCalendarIn);

export default EventCalendar