import { Formik, FormikActions, FormikProps } from 'formik';
import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { ValueType } from 'react-select/lib/types';
import Paginate from '../../components/table/paginate';
import Spinner from '../../components/templates/spinner';
import * as Actions from '../../store/actions/general';
import Announce from '../../components/templates/announce';
import * as Constants from '../../store/constants/all';
import { TermTypes, CourseTermLectureLocations, EducationTypeSelectOptions, Grades, CourseTypes, CourseHourStatusTypes } from '../../store/constants/course-const';
import { ActivityHoursSearchInitialValues, LectureLocationsLabel, ActivityTypesLabel, ActivityTypes, ActivitySearchInitialValues, CourseEnvironments, CourseTermLectureLocationsForSolution } from '../../store/constants/activity-const';
import * as Types from '../../store/types';
import * as GT from '../../tools/general-tools';
import ClassroomAddModal from './classroom-hours-add-modal';
import CombineActivitiesModal from './combined-activities-modal';
import CopyCourseModal from './course-copy-modal';
import CourseEditForm from './course-edit-form';
import HoursExportModal from '../planning/hours-export-modal';
import ImportModal, { ExcelImportKeys } from '../../components/excel-imports/import-modal';
import CourseHoursListPageTableHeaders from './components/course-hours-listpage-tableheaders';
import { SectionTypes } from '../../store/constants/enums';
import { routes as Routes } from '../../store/constants/routes';
import Translator from '../../services/translate-factory';
import { filterClassroomTypesForEventModuleStatus } from '../../util/sort';

const T = Translator.create();
const L = Log.create('CourseAddModal');

class CourseAddModal extends Component<Types.IActivityPageProps, Types.IActivityPageState> {
  state: Types.IActivityPageState = {
    filters: Object.assign({}, ActivityHoursSearchInitialValues),
    filterIsOpen: false,
    all_ids: [],
    selected_ids: [],
    selected_course_ids: [],
    selected_course_hour_ids: [],
    selected_names: [],
    combined_list_items: [],
    selected_activities: [],
    classroomAddModalIsOpen: false,
    hasCombinedActivities: false,
    combineActivitiesModalIsOpen: false,
    activityCopyModalIsOpen: false,
    activityFormIsOpen: false,
    activityNo: undefined,
    editActivity: undefined,
    pageIsChanged: false,
    onDeleteClicked: false,
    onCombinedClicked: false,
    downloadHoursModalIsOpen: false
  };

  constructor(props: any) {
    super(props)

    this.handleFilter = this.handleFilter.bind(this);
  }

  componentDidMount() {
    /*let regexObject = window.location.pathname.match(/([^\/]+$)/); // gets the id of the calendar
    let id = regexObject ? parseInt(regexObject[0], 10) : undefined;*/
    var allNumbersFromPath = (window.location.pathname).replace(/[^0-9]/g, ' ').trim().split(/\s+/);
    let id = parseInt(allNumbersFromPath[allNumbersFromPath.length - 1], 10);
    this.state.filters.term_id = id;
    this.setState(this.state);
    this.getProgramsByFacultiesAtActivities([]);
  }

  componentDidUpdate(prevProps: Types.IActivityPageProps, prevState: Types.IActivityPageState) {
    if (prevProps.activityList !== this.props.activityList) {
      this.setState({
        pageIsChanged: false
      })
    }
    if (prevProps.activityList !== this.props.activityList && !this.state.pageIsChanged) {
      if (this.props.filters && !this.props.filters.select_all) {
        this.setState({
          selected_course_ids: [],
          selected_ids: [],
          selected_activities: [],
          selected_names: []
        })
      }
    }
    if (this.state.onDeleteClicked || this.state.onCombinedClicked) {
      setTimeout(() => {
        if (this.props.is_related) {
          this.props.dispatch(
            Actions.ShowModal({
              title: T.t('gen_has_activity_relations'),
              body: T.t('gen_has_activity_relations_info'),
              name: 'course_delete',
              icon: 'warning',
              iconColor: 'red',
              cancel: T.t('gen_ok'),
              confirm: T.t('gen_go_to_activity_relations'),
              onConfirm: () => {
                this.props.dispatch(Actions.Navigation(GT.Route(Routes.ACTIVITY_RELATIONS)))
              }
            })
          );
          this.state.onDeleteClicked = false;
          this.state.onCombinedClicked = false;
        } else if (!this.props.is_related && this.state.onDeleteClicked) {
          this.props.dispatch(
            Actions.ShowModal({
              title: T.t('gen_courses_remove_planning'),
              content: (
                <div>
                  <ul style={{ listStyleType: 'none' }}>
                    {this.state.filters && this.state.filters.select_all ? T.t('gen_all_courses_selected') :
                      this.state.selected_ids && this.state.selected_ids.length > 10 ? T.t('gen_more_than_x_courses_selected').replace("{0}", 10) :
                        this.state.selected_names && this.state.selected_names.map((courseName: string) => <li>{courseName ? courseName.split(';').join(', ') : ""}</li>)}
                  </ul>
                  <p>{T.t('gen_remove_courses_from_course_schedule_question')}</p>
                </div>
              ),
              name: 'examPeriod_courses_delete',
              icon: '',
              iconColor: '',
              confirm: T.t('gen_yes'),
              cancel: T.t('gen_cancel'),
              onConfirm: () => {
                const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
                  if (status == 200) {
                    if (this.props.clearCheckedItems) {
                      this.props.clearCheckedItems();
                    }
                    this.state.selected_ids = [];
                    this.state.selected_activities = [];
                    this.state.selected_names = [];
                    this.getCourseHours();
                  }
                };

                /*let regexObject = window.location.pathname.match(/([^\/]+$)/); // gets the id of the calendar
                let id = regexObject ? parseInt(regexObject[0], 10) : undefined;*/
                var allNumbersFromPath = (window.location.pathname).replace(/[^0-9]/g, ' ').trim().split(/\s+/);
                let id = parseInt(allNumbersFromPath[allNumbersFromPath.length - 1], 10);
                this.state.filters.term_id = id;

                this.state.filters.delete_list = this.state.selected_ids;
                !this.state.filters.select_all ? this.state.filters.course_ids = this.state.selected_course_ids
                  : this.state.filters.course_ids = this.props.all_course_ids

                this.props.dispatch(
                  Actions.ApiRequest(
                    Constants.exam_period.EXAM_PERIOD_DELETE_COURSES_FROM_PERIOD,
                    this.state.filters,
                    'ep-course-list',
                    resultCallback
                  )
                );
              }
            })
          );
        } else if (!this.props.is_related && this.state.onCombinedClicked) {
          this.setState({
            ...this.state,
            hasCombinedActivities: false,
            combineActivitiesModalIsOpen: !this.state.combineActivitiesModalIsOpen
          });
        }
        this.state.onCombinedClicked = false;
        this.state.onDeleteClicked = false;
      }, 800);
    }

    if (this.props.filters === undefined) {
      this.getCourseHours();
    }
  }

  getCourseHours() {
    var allNumbersFromPath = (window.location.pathname).replace(/[^0-9]/g, ' ').trim().split(/\s+/);
    let id = parseInt(allNumbersFromPath[allNumbersFromPath.length - 1], 10);
    this.state.filters.term_id = id;

    let newModel = {
      activity_noes_ids: this.state.filters.activity_noes_ids,
      course_codes_ids: this.state.filters.course_codes_ids,
      activity_type: this.state.filters.activity_type,
      add_list: this.state.filters.add_list,
      assistant_staff: this.state.filters.assistant_staff,
      course_name: this.state.filters.course_name,
      education_type: this.state.filters.education_types && this.state.filters.education_types.map((i: any) => i.value),
      course_type: this.state.filters.course_type,
      grades_ids: this.state.filters.grades_ids,
      instructor_ids: this.state.filters.instructor_ids,
      instructors: this.state.filters.instructors,
      lecture_location_ids: this.state.filters.lecture_location_ids,
      lecture_locations: this.state.filters.lecture_locations,
      lecture_staff: this.state.filters.lecture_staff,
      lesson_counts_ids: this.state.filters.lesson_counts_ids,
      order_by: this.state.filters.order_by,
      page: this.state.filters.page,
      practical_staff: this.state.filters.practical_staff,
      faculty_ids: this.state.filters.faculty_ids,
      faculties: this.state.filters.faculties,
      program_ids: this.state.filters.program_ids,
      programs: this.state.filters.programs,
      query: this.state.filters.query,
      sections: this.state.filters.sections,
      select_all: this.state.filters.select_all,
      size: this.state.filters.size,
      status: this.state.filters.status,
      term_id: this.state.filters.term_id,
      total: this.state.filters.total,
      course_hour_status_ids: this.state.filters.course_hour_status_ids,
      course_hour_classroom_status_ids: this.state.filters.course_hour_classroom_status_ids,
      course_environment_ids: this.state.filters.course_environment_ids,
      course_language_ids: this.state.filters.course_language_ids,
      classroom_type_ids: this.state.filters.classroom_type_ids,
      week: this.state.filters.week,
      description: this.state.filters.description
    }

    this.props.dispatch(
      Actions.ApiRequest(Constants.exam_period.EXAM_PERIOD_GET_COURSETERM_COURSE_HOURS, newModel, 'ep-course-list')
    );

    let classroomFilter: Types.IFilterClassroom =
    {
      term_id: id,
      page: 1,
      total: -1,
      course_ids: this.state.selectedActivityNo ? [this.state.selectedActivityNo] : [],
      size: 10
    };

    this.props.dispatch(
      Actions.ApiRequest(
        Constants.course_period.COURSE_TERM_ATTACHED_CLASSROOM,
        classroomFilter,
        'course-tab-attached-classrooms'
      )
    );
  }

  getCourseSelectOptions() {
    this.props.dispatch(Actions.ApiRequest(Constants.course.COURSE_GET_SELECT_OPTIONS, 'course-list-spin'));
  }

  getProgramsByFacultiesAtActivities = (facultyIds: any) => {
    this.props.dispatch(Actions.ApiRequest(Constants.activity.ACTIVITY_GET_PROGRAMS_BY_FACULTIES, facultyIds, 'activity-list-spin'));
  }

  setShow = (refresh: boolean = false) => {
    if (this.props.onShowModal) {
      this.props.onShowModal(refresh);
    }
  };

  onShowModal = () => {
    this.setShow();
  };

  switchClassroomModalStatus = () => {
    this.setState({
      ...this.state,
      classroomAddModalIsOpen: !this.state.classroomAddModalIsOpen
    });
  };

  switchCombineActivitiesModalStatus = () => {
    if (this.state.combineActivitiesModalIsOpen == true) {
      this.unCheckAllSelecteds();
    }
    this.setState({
      ...this.state,
      combineActivitiesModalIsOpen: !this.state.combineActivitiesModalIsOpen
    });
  };

  switchCopyExamModalStatus = () => {
    if (this.state.activityCopyModalIsOpen == true) {
      this.unCheckAllSelecteds();
    }
    this.setState({
      ...this.state,
      activityCopyModalIsOpen: !this.state.activityCopyModalIsOpen
    });
  };

  unCheckAllSelecteds = () => {
    this.setState({
      ...this.state,
      selected_ids: [],
      selected_names: [],
      selected_activities: [],
      combined_activities: [],
      selected_all_activities: [],
    });
  };

  showCourseProgram = (e?: any, id?: number) => {
    if (this.props.onSelect) {
      this.props.onSelect(e, id);
      this.state.selectedActivityNo = id;
      this.setState(this.state);
    }
  };

  onShowCourseProgram = (e?: any) => {
    let course_id: number = -1;
    const str_course_id: string = e.currentTarget.dataset.course_id || '';
    course_id = parseInt(str_course_id, 10);
    this.showCourseProgram(e, course_id);
    this.onSelectCourse(e);
  };

  checkAllSelected = (): boolean => {
    const all_ids = this.state.all_ids ? this.state.all_ids : [];
    const selected_ids = this.state.selected_ids ? this.state.selected_ids : [];
    let result: boolean = false;
    if (all_ids.length && selected_ids.length) {
      result = all_ids.every((item: number) => selected_ids.indexOf(item) !== -1);
    }
    return result;
  };

  onSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      if (e.currentTarget.checked) {
        let newModel = {
          activity_noes_ids: this.state.filters.activity_noes_ids ? this.state.filters.activity_noes_ids : null,
          course_codes_ids: this.state.filters.course_codes_ids ? this.state.filters.course_codes_ids : null,
          activity_type: this.state.filters.activity_type ? this.state.filters.activity_type : null,
          add_list: this.state.filters.add_list ? this.state.filters.add_list : null,
          assistant_staff: this.state.filters.assistant_staff && this.state.filters.assistant_staff != undefined ? this.state.filters.assistant_staff : null,
          course_name: this.state.filters.course_name && this.state.filters.course_name != "" ? this.state.filters.course_name : null,
          education_type: this.state.filters.education_types && this.state.filters.education_types.map((i: any) => i.value) ? this.state.filters.education_types && this.state.filters.education_types.map((i: any) => i.value) : null,
          course_type: this.state.filters.course_type ? this.state.filters.course_type : null,
          grades_ids: this.state.filters.grades_ids ? this.state.filters.grades_ids : null,
          instructor_ids: this.state.filters.instructor_ids && this.state.filters.instructor_ids != undefined ? this.state.filters.instructor_ids : null,
          instructors: this.state.filters.instructors && this.state.filters.instructors != undefined ? this.state.filters.instructors : null,
          lecture_location_ids: this.state.filters.lecture_location_ids && this.state.filters.lecture_location_ids != undefined ? this.state.filters.lecture_location_ids : null,
          lecture_locations: this.state.filters.lecture_locations && this.state.filters.lecture_locations != undefined ? this.state.filters.lecture_locations : null,
          lecture_staff: this.state.filters.lecture_staff && this.state.filters.lecture_staff != undefined ? this.state.filters.lecture_staff : null,
          lesson_counts_ids: this.state.filters.lesson_counts_ids ? this.state.filters.lesson_counts_ids : null,
          order_by: this.state.filters.order_by,
          page: this.state.filters.page,
          practical_staff: this.state.filters.practical_staff && this.state.filters.practical_staff != undefined ? this.state.filters.practical_staff : null,
          faculty_ids: this.state.filters.faculty_ids && this.state.filters.faculty_ids != undefined ? this.state.filters.faculty_ids : null,
          faculties: this.state.filters.faculties && this.state.filters.faculties != undefined ? this.state.filters.faculties : null,
          program_ids: this.state.filters.program_ids && this.state.filters.program_ids != undefined ? this.state.filters.program_ids : null,
          programs: this.state.filters.programs && this.state.filters.programs != undefined ? this.state.filters.programs : null,
          query: this.state.filters.query ? this.state.filters.query : null,
          sections: this.state.filters.sections && this.state.filters.sections != undefined ? this.state.filters.sections : null,
          select_all: true,
          size: this.state.filters.size,
          status: this.state.filters.status && this.state.filters.status != undefined ? this.state.filters.status : null,
          term_id: this.state.filters.term_id ? this.state.filters.term_id : null,
          total: this.state.filters.total ? this.state.filters.total : null,
          course_hour_status_ids: this.state.filters.course_hour_status_ids,
          course_hour_classroom_status_ids: this.state.filters.course_hour_classroom_status_ids,
          week: this.state.filters.week ? this.state.filters.week : null,
          course_language_ids: this.state.filters.course_language_ids ? this.state.filters.course_language_ids : null,
          course_environment_ids: this.state.filters.course_environment_ids ? this.state.filters.course_environment_ids : null,
          classroom_type_ids: this.state.filters.classroom_type_ids ? this.state.filters.classroom_type_ids : null,
          description: this.state.filters.description ? this.state.filters.description : null
        };

        this.state.filters.select_all = true;
        this.setState(this.state);
        this.props.dispatch(
          Actions.ApiRequest(
            Constants.exam_period.EXAM_PERIOD_GET_COURSETERM_COURSE_HOURS,
            newModel,
            'ep-course-list',
            (response: any) => {
              const allCourseIds = response.all_course_ids ? response.all_course_ids as number[] : null;

              const selected_activities: ({ id: number, item: Types.IExamPeriod_ActivityItem })[] | undefined = [];
              const selected_all_activities: ({ id: number, item: Types.IExamPeriod_ActivityItem })[] | undefined = [];

              if (response.data) {
                response.data.forEach((element: any) => {
                  const info = element as Types.IExamPeriod_ActivityItem;
                  const courseId = info.course_id ? info.course_id : 0;
                  if (courseId > 0 && allCourseIds && allCourseIds.includes(courseId)) {
                    selected_activities.push({ id: courseId, item: info });
                  }
                });
              }

              if (response.selectAllData) {
                response.selectAllData.forEach((element: any) => {
                  const info = element as Types.IExamPeriod_ActivityItem;
                  const courseId = info.course_id ? info.course_id : 0;
                  if (courseId > 0 && allCourseIds && allCourseIds.includes(courseId)) {
                    selected_all_activities.push({ id: courseId, item: info });
                  }
                });
              }

              let checkedNameList = selected_activities && selected_activities.map((x: any) => x.item.activityInfo ? x.item.activityInfo.course_code + ' - ' + x.item.activityInfo.course_name : "");

              this.setState({
                ...this.state,
                all_ids: response.all_ids,
                selected_ids: response.all_ids,
                selected_course_ids: response.all_course_ids,
                selected_activities: selected_activities,
                selected_all_activities: selected_all_activities,
                selected_names: checkedNameList
              });
            }
          )
        );
      } else {
        this.setState({
          ...this.state,
          selected_ids: [],
          selected_course_ids: [],
          selected_activities: [],
          selected_all_activities: [],
          selected_names: [],
          filters: {
            ...this.state.filters,
            select_all: false
          }
        });
      }
    }
  };

  checkIfAllCoursesSelected = () => {
    const resultCallback = (response: any, status: number) => {
      if (status === 200) {

        const all_ids = response.all_course_ids ? response.all_course_ids : [];
        const selected_course_ids = this.state.selected_course_ids ? this.state.selected_course_ids : [];
        let result: boolean = false;
        if (all_ids.length && selected_course_ids.length) {
          result = all_ids.every((item: number) => selected_course_ids.indexOf(item) !== -1);
        }

        if (result) {
          this.state.all_ids = response.all_course_ids;
          this.state.filters.select_all = true;
          this.setState(this.state);
        }
      }
    };
    let filter = this.state.filters;
    filter.select_all = true;
    this.props.dispatch(
      Actions.ApiRequest(
        Constants.exam_period.EXAM_PERIOD_GET_COURSETERM_COURSE_HOURS,
        filter,
        'course-hours-spinner',
        resultCallback
      )
    );
  };

  onSelectCourse = (e: any) => {
    if (e && e.currentTarget) {
      if (this.props.onCheck) {
        this.props.onCheck(e);
      }
      let checkedIDList: Array<number> = Object.assign([], this.state.selected_ids);
      let selected_course_ids: Array<number> = Object.assign([], this.state.selected_course_ids);

      let checkedNameList = Object.assign([], this.state.selected_names);
      let checkedCourses = Object.assign([], this.state.selected_activities);
      let stringID: any = e.currentTarget.dataset.id || -1;
      let courseName: string = e.currentTarget.dataset.name || '';
      let string_courseItem: string = e.currentTarget.dataset.item || '';
      let courseItem: Types.IExamPeriod_ActivityItem = JSON.parse(string_courseItem);
      let courseID = parseInt(stringID, 10);

      if (selected_course_ids.findIndex((_id: number) => _id === courseItem.course_id) === -1) {
        selected_course_ids.push(courseItem.course_id ? courseItem.course_id : -1)
      } else {
        selected_course_ids = selected_course_ids.filter((_id: number) => _id != courseItem.course_id)
      }
      if (checkedIDList.findIndex((_id: number) => _id === courseID) === -1) {
        checkedIDList.push(courseID);
        checkedNameList.push(courseName);
        checkedCourses.push({ id: courseID, item: courseItem });
      } else {
        let index = checkedIDList.indexOf(courseID);
        let nameIndex = checkedNameList.indexOf(courseName);
        let courseItem: any = {};
        courseItem = checkedCourses.find(
          (item: { id: number; item: Types.IExamPeriod_ActivityItem }) => item.id === courseID
        );
        let courseItemIndex = courseItem ? courseItem.id : -1;
        if (index !== -1) {
          checkedIDList.splice(index, 1);
        }
        if (nameIndex !== -1) {
          checkedNameList.splice(nameIndex, 1);
        }
        if (courseItemIndex !== -1) {
          checkedCourses = checkedCourses.filter((item: any) => item.id != courseID);
        }
        this.setState({
          selected_ids: checkedIDList,
          selected_names: checkedNameList,
          selected_activities: checkedCourses,
          filters: {
            ...this.state.filters,
            select_all: false
          }
        });
      }
      this.setState({
        selected_ids: checkedIDList,
        selected_course_ids: selected_course_ids,
        selected_names: checkedNameList,
        selected_activities: checkedCourses
      });
    }
    this.setState({
      ...this.state,
      selected_course_ids: this.state.selected_course_ids
    });
    if (this.props.updateCourseHourIdList) {
      this.props.updateCourseHourIdList(this.state.selected_ids);
    }
    if (this.props.updateCheckedList) {
      this.props.updateCheckedList(this.state.selected_course_hour_ids);
    }
    if (this.state.activity_list && this.state.activity_list.length > 0 && this.state.selected_course_ids && this.state.activity_list.length == this.state.selected_course_ids.length) {
      this.checkIfAllCoursesSelected();
    }
  };

  onSelectCourseCalledFromCoursesTab = (e: any) => {
    if (this.props.onCheck) {
      this.props.onCheck(e);
    }

    let checkedIDList: Array<number> = Object.assign([], this.state.selected_ids);
    let selected_course_ids: Array<number> = Object.assign([], this.state.selected_course_ids);

    let checkedNameList = Object.assign([], this.state.selected_names);
    let checkedCourses = Object.assign([], this.state.selected_activities);
    let checkedAllCourses = Object.assign([], this.state.selected_all_activities);
    let stringID: any = e.currentTarget.dataset.id || -1;
    let courseName: string = e.currentTarget.dataset.name || '';
    let string_courseItem: string = e.currentTarget.dataset.item || '';
    let courseItem: Types.IExamPeriod_ActivityItem = JSON.parse(string_courseItem);
    let courseID = parseInt(stringID, 10);

    if (selected_course_ids.findIndex((_id: number) => _id === courseItem.course_id) === -1) {
      selected_course_ids.push(courseItem.course_id ? courseItem.course_id : -1)
    } else {
      selected_course_ids = selected_course_ids.filter((_id: number) => _id != courseItem.course_id)
    }

    if (checkedIDList.findIndex((_id: number) => _id === courseID) === -1) {
      checkedIDList.push(courseID);
      checkedNameList.push(courseName);
      checkedCourses.push({ id: courseID, item: courseItem });
      checkedAllCourses.push({ id: courseID, item: courseItem });
    } else {
      let index = checkedIDList.indexOf(courseID);
      let nameIndex = checkedNameList.indexOf(courseName);

      let courseItem: any = {};
      courseItem = checkedCourses.find(
        (item: { id: number; item: Types.IExamPeriod_ActivityItem }) => item.id === courseID
      );
      let courseItemIndex = courseItem ? courseItem.id : -1;

      let courseAllItem: any = {};
      courseAllItem = checkedAllCourses.find(
        (element: { id: number; item: Types.IExamPeriod_ActivityItem }) => element.item.course_hour_id === courseID
      );
      let courseAllItemIndex = courseAllItem ? courseAllItem.id : -1;

      if (index !== -1) {
        checkedIDList.splice(index, 1);
      }
      if (nameIndex !== -1) {
        checkedNameList.splice(nameIndex, 1);
      }
      if (index !== -1) {
        checkedCourses.splice(index, 1);
      }
      if (courseItemIndex !== -1) {
        checkedCourses = checkedCourses.filter((item: any) => item.id != courseID);
      }
      if (courseAllItemIndex !== -1) {
        checkedAllCourses = checkedAllCourses.filter((element: any) => element.item.course_hour_id != courseID);
      }

      this.setState({
        selected_ids: checkedIDList,
        selected_names: checkedNameList,
        selected_activity: courseItem,
        selected_activities: checkedCourses,
        selected_all_activities: checkedAllCourses,
        filters: {
          ...this.state.filters,
          select_all: false
        }
      });
    }

    this.setState({
      selected_ids: checkedIDList,
      selected_course_ids: selected_course_ids,
      selected_names: checkedNameList,
      selected_activity: courseItem,
      selected_activities: checkedCourses,
      selected_all_activities: checkedAllCourses
    });
    if (this.state.activity_list && this.state.activity_list.length > 0 && this.state.activity_list.length == selected_course_ids.length) {
      this.checkIfAllCoursesSelected();
    }
  };

  onSelectCourseCheckbox = async (e: any) => {
    if (e && e.currentTarget) {
      let checkedIDList: Array<number> = Object.assign([], this.state.selected_ids);
      let selected_course_ids: Array<number> = Object.assign([], this.state.selected_course_ids);

      let checkedNameList = Object.assign([], this.state.selected_names);
      let checkedCourses = Object.assign([], this.state.selected_activities);
      let stringID: any = e.currentTarget.dataset.id || -1;
      let courseName: string = e.currentTarget.dataset.name || '';
      let string_courseItem: string = e.currentTarget.dataset.item || '';
      let courseItem: Types.IExamPeriod_ActivityItem = JSON.parse(string_courseItem);
      let courseID = parseInt(stringID, 10);

      if (selected_course_ids.findIndex((_id: number) => _id === courseItem.course_id) === -1) {
        selected_course_ids.push(courseItem.course_id ? courseItem.course_id : -1)
      } else {
        selected_course_ids = selected_course_ids.filter((_id: number) => _id != courseItem.course_id)
      }

      if (checkedIDList.findIndex((_id: number) => _id === courseID) === -1) {
        checkedIDList.push(courseID);
        checkedNameList.push(courseName);
        checkedCourses.push({ id: courseID, item: courseItem });
      } else {

        let index = checkedIDList.indexOf(courseID);
        let nameIndex = checkedNameList.indexOf(courseName);
        let courseItem: any = {};

        courseItem = checkedCourses.find(
          (item: { id: number; item: Types.IExamPeriod_ActivityItem }) => item.id === courseID
        );

        let courseItemIndex = courseItem ? courseItem.id : -1;

        if (index !== -1) {
          checkedIDList.splice(index, 1);
        }

        if (nameIndex !== -1) {
          checkedNameList.splice(nameIndex, 1);
        }

        if (courseItemIndex !== -1) {
          checkedCourses = checkedCourses.filter((item: any) => item.id != courseID);
        }

        this.setState({
          selected_ids: checkedIDList,
          selected_names: checkedNameList,
          selected_activity: courseItem,
          selected_activities: checkedCourses,
          filters: {
            ...this.state.filters,
            select_all: false
          }
        });

      }

      await this.setState({
        selected_ids: checkedIDList,
        selected_course_hour_ids: checkedIDList,
        selected_course_ids: selected_course_ids,
        selected_names: checkedNameList,
        selected_activity: courseItem,
        selected_activities: checkedCourses
      });
    }

    if (this.props.updateCourseHourIdList) {
      this.props.updateCourseHourIdList(this.state.selected_ids);
    }
    if (this.props.updateCheckedList) {
      this.props.updateCheckedList(this.state.selected_course_hour_ids);
    }
    if (this.props.saveAllCheckboxes) {
      this.props.saveAllCheckboxes(this.state.selected_ids);
    }
    if (this.state.activity_list && this.state.selected_course_ids && this.state.activity_list.length > 0 && this.state.activity_list.length == this.state.selected_course_ids.length) {
      this.checkIfAllCoursesSelected();
    }
  };

  onCheckIfAllSelected = (): boolean => {
    let courseList = this.state.activity_list;
    const all_ids = this.state.all_ids ? this.state.all_ids : [];
    const selected_course_ids = this.state.selected_course_ids ? this.state.selected_course_ids : [];
    let result: boolean = false;
    if (all_ids.length && selected_course_ids.length) {
      result = all_ids.every((item: number) => selected_course_ids.indexOf(item) !== -1);
      selected_course_ids.filter((_id: any) => _id == (courseList && courseList.map((item: any) => item.course_id)))
    }
    if (selected_course_ids.length < all_ids.length) {
      return false;
    } else return true;
  }

  onCheck = (): boolean => {
    let result: boolean = false;
    let courseList = this.state.activity_list;
    courseList && courseList.map((item: any) => {
      if ((this.props.filters && this.props.filters.total) !== (this.state.all_ids && this.state.all_ids.length)) {
        if (((this.state.all_ids && this.state.all_ids.length) == (this.state.selected_ids && this.state.selected_ids.length)) && this.state.all_ids && this.state.all_ids.length !== 0) {
          result = true;
        } else {
          if ((this.state.selected_ids && this.state.selected_ids.length == 0) || ((this.state.selected_ids && this.state.selected_ids.length) !== (this.state.selected_course_ids && this.state.selected_course_ids.length))) {
            result = (this.state.selected_course_ids! && this.state.selected_course_ids!.indexOf(item.course_id ? item.course_id : -1) > -1)
          } else {
            result = false;
          }
        }
      } else {
        result = this.onCheckIfAllSelected()
      }
    })
    return result;
  }

  checkAllIdsSelected = (): boolean => {
    const all_ids = this.state.all_ids ? this.state.all_ids : [];
    const selected_ids = this.state.selected_ids ? this.state.selected_ids : [];
    let result: boolean = false;
    if (all_ids.length && selected_ids.length) {
      result = all_ids.every((item: number) => selected_ids.indexOf(item) !== -1);
    }
    return result;
  };

  saveCombinedCourses = (
    combinedActivities?: Array<Types.IExamPeriod_ActivityItem>,
    activityObject?: Types.IExamPeriod_ActivityItem
  ) => {
    let activityArray: any = [];
    if (combinedActivities) {
      combinedActivities.map((item: Types.IExamPeriod_ActivityItem) => {
        activityArray.push({ id: item.activity_no, item: item });
      });
    }
    this.setState({
      ...this.state,
      hasCombinedActivities: true,
      combined_activities: activityArray,
      activityObject: activityObject,
      combineActivitiesModalIsOpen: !this.state.combineActivitiesModalIsOpen
    });
  };

  deleteCourses = (e: React.MouseEvent<HTMLSpanElement>) => {
    this.setState({
      onDeleteClicked: true
    });
    if (e && e.target) {
      const deleteList = this.state.selected_course_ids;
      this.props.dispatch(
        Actions.ApiRequest(Constants.activity.ACTIVITY_IS_RELATED, deleteList, 'activity-list-spin')
      );
    }
  };

  onInputChange = (inputValue: string, action: any) => {
    if (action.action === "input-change") {
      this.state.course_codes_filtered = this.props.selectOptions && this.props.selectOptions.course_codes.filter((item: any) => item.label.toLowerCase().includes(inputValue.toLowerCase()));
    }

    if (action.action === "set-value" && this.state.course_codes_filtered == undefined) {
      this.state.course_codes_filtered = this.props.selectOptions && this.props.selectOptions.course_codes;
    }

    if (action.action === "menu-close" && this.state.course_codes_filtered != undefined) {
      this.state.course_codes_filtered = undefined;
    }
  };

  filterOption = (option: Types.ISelectOption, inputValue: string) => {
    if (option.value === -1) {
      return true;
    } else if (inputValue) {
      return option.label.toLowerCase().includes(inputValue.toLowerCase());
    } else {
      return true;
    }
  };

  sort = (sortkey: string, order_by: string) => {
    /*let regexObject = window.location.pathname.match(/([^\/]+$)/); // gets the id of the calendar
    let id = regexObject ? parseInt(regexObject[0], 10) : undefined;*/
    var allNumbersFromPath = (window.location.pathname).replace(/[^0-9]/g, ' ').trim().split(/\s+/);
    let id = parseInt(allNumbersFromPath[allNumbersFromPath.length - 1], 10);
    this.state.filters.term_id = id;
    this.state.filters.order_by = sortkey + '_' + order_by;
    this.setState(this.state);
    this.getCourseHours();
  };

  onPageChange = (page: number) => {
    this.state.filters.page = page;
    this.state.pageIsChanged = true;
    this.setState(this.state);
    this.getCourseHours();
  };

  onFormReset = () => {
    this.state.filters = Object.assign({}, ActivityHoursSearchInitialValues);
    GT.GetTermIdFromURL(window, this.state.filters.term_id);
    this.setState(this.state);
    this.getCourseHours();
  };

  onFilterCourse(model: Types.IFilterActivity, FormActions: FormikActions<Types.IFilterActivity>) {
    GT.GetTermIdFromURL(window, this.state.filters.term_id);
    this.state.filters = model;
    this.state.filters.page = 1;
    this.state.filterIsOpen = true;
    this.state.pageIsChanged = true;
    this.setState(this.state);
    this.getCourseHours();
    FormActions.setSubmitting(false);
  }

  isAvailableToCombine = () => {
    this.setState({
      onCombinedClicked: true
    });
    const deleteList = this.state.selected_course_ids;
    this.props.dispatch(
      Actions.ApiRequest(Constants.activity.ACTIVITY_IS_RELATED, deleteList, 'activity-list-spin')
    );

    let isSelectedAlreadyCombined: boolean = false;
    let selected_ids = this.state && this.state.selected_ids;
    let combined_ids = this.state && this.state.combined_list_items;
    selected_ids &&
      selected_ids.map((selectedID: number) => {
        if (combined_ids && combined_ids.includes(selectedID)) {
          isSelectedAlreadyCombined = true;
        }
      });
    if (isSelectedAlreadyCombined) {
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_error'),
          body: T.t('gen_activity_combine_warning'),
          name: 'examPeriod_delete',
          icon: 'error_outline',
          iconColor: 'red',
          cancel: T.t('gen_close')
        })
      );
    }
  };

  showInfoModalCourseSectionsUpdate = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (e && e.target) {
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_activity_course_sections_update_title'),
          body: T.t('gen_activity_course_sections_update_body'),
          name: 'course sections update',
          icon: 'info',
          iconColor: 'blue',
          confirm: T.t('gen_yes'),
          cancel: T.t('gen_cancel'),
          onConfirm: () => {
            const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
              if (status == 208) {
                this.props.dispatch(
                  Actions.ShowModal({
                    title: T.t('gen_activity_course_sections_update_already_completed_title'),
                    body: T.t('gen_activity_course_sections_update_already_completed_body'),
                    name: 'course sections update already completed',
                    icon: 'info',
                    iconColor: 'blue',
                    confirm: T.t('gen_yes'),
                    cancel: T.t('gen_cancel'),
                    onConfirm: () => {
                      const model = {
                        term_id: this.state.filters.term_id,
                        validation: true
                      };
                      this.props.dispatch(Actions.ApiRequest(Constants.activity.ACTIVITY_COURSE_SECTIONS_UPDATE, model, '', resultCallback));
                    }
                  })
                );
              }
            };
            const model = {
              term_id: this.state.filters.term_id,
              validation: false
            };
            this.props.dispatch(Actions.ApiRequest(Constants.activity.ACTIVITY_COURSE_SECTIONS_UPDATE, model, '', resultCallback));
          }
        })
      );
    }
  };

  onHoursImported = () => {
    this.getCourseHours();
  };

  onDownloadHours = () => {
    this.setState({
      ...this.state,
      downloadHoursModalIsOpen: !this.state.downloadHoursModalIsOpen
    });
  }

  switchDownloadHoursModalStatus = () => {
    this.setState({
      ...this.state,
      downloadHoursModalIsOpen: !this.state.downloadHoursModalIsOpen
    });
  };

  static getDerivedStateFromProps(props: Types.IActivityPageProps, state: Types.IActivityPageState) {
    let hasNewState: boolean = false;
    if (props.activityList) {
      hasNewState = true;
      state.activity_list = props.activityList;
    }
    if (props.compactTable && props.selectedActivityNo && !!!state.selectedActivityNo) {
      hasNewState = true;
      state.selectedActivityNo = props.selectedActivityNo;
    }
    if (props.all_ids && props.all_ids.length) {
      hasNewState = true;
      state.all_ids = props.all_ids;
    }
    if (
      props.compactTable &&
      props.checkedActivityNos &&
      props.checkedActivityNos.length > 0 &&
      state.selected_ids &&
      state.selected_ids.length == 0
    ) {
      hasNewState = true;
    }
    if (
      props.compactTable &&
      props.checkedActivityNos &&
      props.checkedActivityNos.length == 0 &&
      state.selected_ids &&
      state.selected_ids.length > 0
    ) {
      hasNewState = true;
    }
    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  nestedButton() {
    if (this.state.selected_ids && this.state.selected_ids.length !== 0) {
      return (
        <button
          id='button_check_item'
          className="float-left pl-2 pr-2 category-tag-square tag-gray"
          style={{ margin: '2px' }}
          onClick={() => {
            if (this.props.clearCheckedItems) {
              this.props.clearCheckedItems();
            }
            this.setState((prev) => ({
              ...prev,
              selected_ids: [],
              selected_names: [],
              selected_activities: [],
              selected_course_ids: [],
              all_ids: []
            }));
          }}
        >
          <i className="mr-2 material-icons">close</i>
          <span id='span_cancel'>{this.props.compactTable ? T.t('gen_cancel') : T.t('gen_cancel_selection')}</span>
          <b>
            &nbsp;(
            {this.state.selected_ids && this.state.selected_ids.length}
            )
          </b>
        </button>
      )
    } else {
      return (
        <button
          id='button_playlist_add'
          className="float-left pl-2 pr-2 category-tag-square tag-green"
          onClick={() => {
            if (this.props.compactTable && this.props.toggleAddModal) {
              this.props.toggleAddModal();
            } else {
              this.onShowModal();
            }
          }}
        >
          <i className="mr-2 material-icons">playlist_add</i>
          <span>{T.t('gen_add_course')}</span>
        </button>
      )
    }
  }

  nestedButton2() {
    if (this.state.selected_ids && this.state.selected_ids.length !== 0) {

      return (
        <button
          id='button_delete_outline'
          className="float-left pl-2 pr-2 category-tag-square"
          style={{ margin: '2px', color: '#fff', backgroundColor: '#dc3545' }}
          onClick={this.deleteCourses}
        >
          <i className="mr-2 material-icons" style={{ color: 'white' }}>
            delete_outline
          </i>
          <span>{this.props.compactTable ? T.t('gen_delete') : T.t('gen_delete_selected')}</span>
        </button>)
    }
    return null

  }

  nestedButton3() {
    if (this.state.selected_ids && this.state.selected_ids.length !== 0) {
      return (
        <button
          id='button_assign_classroom'
          className="float-left pl-2 pr-2 category-tag-square tag-green"
          style={{ margin: '2px' }}
          onClick={this.switchClassroomModalStatus}
        >
          <i className="mr-2 material-icons">input</i>
          <span>{T.t('gen_assign_classroom')}</span>
        </button>
      )
    }
    return null

  }

  handleFilter() {
    this.setState((prev) => ({
      ...prev,
      filterIsOpen: !prev.filterIsOpen
    }));
  }

  render() {

    const courseCodeOptions = this.props.selectOptions && this.props.selectOptions.course_codes ? this.props.selectOptions.course_codes : []
    const courseCodeSelectOptions = [{ label: T.t('gen_select_all'), value: -1 }, ...courseCodeOptions];

    const programOptions = this.props && this.props.programs_related_faculty ? this.props.programs_related_faculty : []
    const programSelectOptions = [{ label: T.t('gen_select_all'), value: -1 }, ...programOptions];

    let activityTypes = ActivityTypes(T);
    if (this.props.selectOptions && this.props.selectOptions.additional_activity_types && this.props.selectOptions.additional_activity_types.length > 0) {
      activityTypes = [...ActivityTypes(T), ...this.props.selectOptions.additional_activity_types].sort((a, b) => (a.label.toLowerCase() >= b.label.toLocaleLowerCase() ? 1 : -1));
    }

    let courseList = this.state.activity_list;
    const campusOptions =
      this.props.selectOptions && this.props.selectOptions.campuses ? this.props.selectOptions.campuses : [];
    const allSelectOptions = [...CourseTermLectureLocations(T), ...campusOptions];

    let Table = (
      <div className="row">
        <div className="col-12">
          <div className="quick-actions">
            <span
              id='span_filter'
              className="float-right category-tag-square tag-glass"
              onClick={this.handleFilter}
              onKeyDown={(e) => {
                if (e.key === '[') {
                  this.handleFilter();
                }
              }}
            >
              <i data-toggle="tooltip" className="material-icons" data-original-title="Listeye Ekle">
                filter_list
              </i>
              {T.t('gen_filter')}
            </span>
            {(this.state.selected_course_ids && this.state.selected_course_ids.length) ? (
              <React.Fragment>
                {this.props.user && (this.props.user.role === 's' || this.props.user.role === 'a' || this.props.user.role === 'e' || this.props.user.role === 'p') ? (this.nestedButton()) : null}
                {this.props.user && (this.props.user.role === 's' || this.props.user.role === 'a' || this.props.user.role === 'e' || this.props.user.role === 'p') ? (this.nestedButton2()) : null}
                {this.props.user && (this.props.user.role == 's' || this.props.user.role == 'a' || this.props.user.role == 'e' || this.props.user.role === 'p') ? (this.nestedButton3()) : null}
                {!this.props.compactTable &&
                  this.state.selected_ids &&
                  this.state.selected_ids.length &&
                  this.state.selected_ids.length < 2 ? (
                  <button
                    id='button_content_copy'
                    className="float-left pl-2 pr-2 category-tag-square"
                    style={{ margin: '2px', color: '#fff', background: 'indigo' }}
                    onClick={() => {
                      this.setState({
                        activityCopyModalIsOpen: !this.state.activityCopyModalIsOpen
                      })
                    }
                    }
                  >
                    <i className="mr-2 material-icons" style={{ color: 'white' }}>
                      content_copy
                    </i>
                    <span>{T.t('gen_copy_activity')}</span>
                  </button>
                ) : null}
                {!this.props.compactTable && this.state.selected_ids && this.state.selected_ids.length > 1 ? (
                  <button
                    id='button_call_merge'
                    className="float-left pl-2 pr-2 category-tag-square tag-blue"
                    style={{ margin: '2px' }}
                    onClick={this.isAvailableToCombine}
                  >
                    <i className="mr-2 material-icons" style={{ color: 'white' }}>
                      call_merge
                    </i>
                    <span>{T.t('gen_activity_combine')}</span>
                  </button>
                ) : null}
              </React.Fragment>
            ) : (
              <React.Fragment>
                {this.props.user && (this.props.user.role === 's' || this.props.user.role === 'a' || this.props.user.role === 'e' || this.props.user.role === 'p') ? (
                  <button
                    id='button_add_course'
                    className="float-left pl-2 pr-2 category-tag-square tag-green"
                    onClick={() => {
                      if (this.props.compactTable && this.props.toggleAddModal) {
                        this.props.toggleAddModal();
                      } else {
                        this.onShowModal();
                      }
                    }}
                  >
                    <i className="mr-2 material-icons">playlist_add</i>
                    <span>{T.t('gen_add_course')}</span>
                  </button>
                ) : null}
                {!this.props.compactTable && this.props.user && this.props.user.role === 's' ? (
                  <button id='course_sections_update' className="ml-1 category-tag-square tag-orange" onClick={this.showInfoModalCourseSectionsUpdate}>
                    <i className="mr-2 material-icons">cached</i>
                    <span>{T.t('gen_course_sections_update')}</span>
                  </button>
                ) : null}
                {this.props.compactTable && this.props.user && (this.props.user.role === 's') ? (
                  <ImportModal
                    title={T.t('gen_add_with_excel')}
                    componentKey={ExcelImportKeys.PlanningCourseHours}
                    dispatch={this.props.dispatch}
                    onImport={this.onHoursImported}
                  />
                ) : null}
                {this.props.compactTable && this.props.user && this.props.user.role == 's' ? (
                  <button className="category-tag-square tag-blue ml-1 pl-2" onClick={this.onDownloadHours}>
                    <i className="material-icons mr-2" style={{ color: 'white' }}>cloud_download</i>
                    <span>{T.t('gen_download')}</span>
                  </button>
                ) : null}
              </React.Fragment>
            )}
          </div>
        </div>
        <div className={`white-container mt-4 collapse ` + (this.state.filterIsOpen ? `show` : ``)} id="advance-search">
          <div className="mt-3 advance-search d-block">
            <Formik
              initialValues={ActivitySearchInitialValues}
              enableReinitialize={true}
              onSubmit={(values, actions) => {
                this.onFilterCourse(values, actions);
              }}
              onReset={this.onFormReset}
            >
              {(props: FormikProps<Types.IFilterActivity>) => {
                return (
                  <form onSubmit={props.handleSubmit}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        props.handleSubmit();
                      }
                    }}
                  >
                    <div className="row">
                      <div className="col-12">
                        <h6>{T.t('gen_filter_parameters')}</h6>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3 add-custom-tag">
                          <div className="react-select-container">
                            <label>{T.t('gen_activity_no')}</label>
                            <Select
                              id='select_activity_no'
                              className="react-select"
                              isMulti={true}
                              closeMenuOnSelect={false}
                              options={
                                this.props.selectOptions && this.props.selectOptions.activity_noes
                                  ? this.props.selectOptions.activity_noes
                                  : []
                              }
                              placeholder={T.t('gen_select_activity_no')}
                              value={props.values.activity_noes}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('activity_noes', list);
                                props.setFieldValue(
                                  'activity_noes_ids',
                                  list.map((item) => item.value)
                                );
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_activity')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3 add-custom-tag">
                          <div className="react-select-container">
                            <label>{T.t('gen_course_code')}</label>
                            <Select
                              id='select_course_code'
                              className="react-select"
                              isMulti={true}
                              closeMenuOnSelect={false}
                              options={courseCodeSelectOptions}
                              placeholder={T.t('gen_select_course_code')}
                              value={props.values.course_codes}
                              filterOption={this.filterOption}
                              onInputChange={this.onInputChange}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('course_codes', list.map(item => item.value).includes(-1) ? this.state.course_codes_filtered! : list);
                                props.setFieldValue(
                                  'course_codes_ids',
                                  list.map(item => item.value).includes(-1) ? this.state.course_codes_filtered!.map((item: any) => item.value) : list.map(item => item.value)
                                );
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_activity')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3 add-custom-tag">
                          <div className="react-select-container">
                            <label>{T.t('gen_activity_type')}</label>
                            <Select
                              id='select_activity_type'
                              className="react-select"
                              isMulti={false}
                              filterOption={(option: any, query: any) =>
                                option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                              }
                              closeMenuOnSelect={true}
                              options={activityTypes}
                              placeholder={T.t('gen_select_activity_type')}
                              value={
                                props.values.activity_type != undefined && props.values.activity_type != null
                                  ? activityTypes.find((option) => option.value == props.values.activity_type)
                                  : null
                              }
                              onChange={(option: any) => {
                                props.setFieldValue('activity_type', option && option.value);
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_activity_type')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mt-3 col-md-4">
                        <div className="form-input form-group date-picker">
                          <input
                            id="course_name"
                            name="course_name"
                            value={props.values.course_name}
                            onChange={props.handleChange}
                            type="text"
                            required
                          />
                          <span className="highlight" />
                          <span className="bar" />
                          <label htmlFor="course_name">{T.t('gen_name')}</label>
                          <i className="material-icons">title</i>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3 add-custom-tag">
                          <div className="react-select-container">
                            <label>{T.t('gen_lesson_location')}</label>
                            <Select
                              id='select_location'
                              className="react-select"
                              isMulti={true}
                              filterOption={(option: any, query: any) =>
                                option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                              }
                              closeMenuOnSelect={false}
                              options={allSelectOptions}
                              placeholder={T.t('gen_select_location')}
                              value={props.values.lecture_locations ? props.values.lecture_locations : null}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('lecture_locations', list);
                                props.setFieldValue(
                                  'lecture_location_ids',
                                  list.map((item) => item.value)
                                );
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_location')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="react-select-container">
                          <label>{T.t('gen_faculty')}</label>
                          <Select
                            id='select_faculty'
                            className="react-select"
                            isMulti={true}
                            filterOption={(option: any, query: any) =>
                              option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                            }
                            closeMenuOnSelect={false}
                            options={
                              this.props.selectOptions && this.props.selectOptions.faculties
                                ? this.props.selectOptions.faculties
                                : []
                            }
                            placeholder={T.t('gen_select_faculty')}
                            value={props.values.faculties}
                            onChange={(
                              options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                            ) => {
                              const list: Array<Types.ISelectOption> = options
                                ? (options as Array<Types.ISelectOption>)
                                : [];
                              props.setFieldValue('faculties', list);
                              props.setFieldValue(
                                'faculty_ids',
                                list.map((item) => item.value)
                              );
                              props.setFieldValue('programs', []);
                              props.setFieldValue('program_ids', []);
                              this.getProgramsByFacultiesAtActivities(list.map(item => item.value))
                            }}
                            noOptionsMessage={(): string => T.t('gen_select_no_faculty')}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3 add-custom-tag">
                          <div className="react-select-container">
                            <label>{T.t('gen_program')}</label>
                            <Select
                              id='select_program'
                              className="react-select"
                              isMulti={true}
                              filterOption={(option: any, query: any) =>
                                option.label && option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                              }
                              closeMenuOnSelect={false}
                              options={programSelectOptions}
                              placeholder={T.t('gen_select_program')}
                              value={props.values.programs}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('programs',
                                  list.map(item => item.value).includes(-1) ? this.props.programs_related_faculty : list);
                                props.setFieldValue(
                                  'program_ids',
                                  list.map(item => item.value).includes(-1) ? programOptions.map((item: any) => item.value) : list.map(item => item.value)
                                );
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_program')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3 add-custom-tag">
                          <div className="react-select-container">
                            <label>{T.t('gen_course_type')}</label>
                            <Select
                              id='select_course_type'
                              className="react-select"
                              isMulti={true}
                              closeMenuOnSelect={false}
                              options={CourseTypes(T)}
                              placeholder={T.t('gen_select_course_type')}
                              value={props.values.course_types}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('course_types', list);
                                props.setFieldValue(
                                  'course_type',
                                  list.map((item) => item.value)
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3 add-custom-tag">
                          <div className="react-select-container">
                            <label>{T.t('gen_theorical_instructors')}</label>
                            <Select
                              id='select_instructor'
                              className="react-select"
                              isMulti={true}
                              filterOption={(option: any, query: any) =>
                                option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                              }
                              closeMenuOnSelect={false}
                              options={
                                this.props.selectOptions && this.props.selectOptions.instructors
                                  ? this.props.selectOptions.instructors
                                  : []
                              }
                              placeholder={T.t('gen_select_instructor')}
                              value={props.values.instructors}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('instructors', list);
                                props.setFieldValue(
                                  'instructor_ids',
                                  list.map((item) => item.value)
                                );
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_instructor')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3 add-custom-tag">
                          <div className="react-select-container">
                            <label>{T.t('gen_lesson_count')}</label>
                            <Select
                              id='select_lesson_count'
                              className="react-select"
                              isMulti={true}
                              closeMenuOnSelect={false}
                              options={
                                this.props.selectOptions && this.props.selectOptions.lesson_counts
                                  ? this.props.selectOptions.lesson_counts
                                  : []
                              }
                              placeholder={T.t('gen_select_lesson_count')}
                              value={props.values.lesson_counts}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('lesson_counts', list);
                                props.setFieldValue(
                                  'lesson_counts_ids',
                                  list.map((item) => item.value)
                                );
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_lesson_count')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3 add-custom-tag">
                          <div className="react-select-container">
                            <label>{T.t('gen_education_type')}</label>
                            <Select
                              id='select_education_type'
                              className="react-select"
                              isMulti={true}
                              closeMenuOnSelect={false}
                              options={EducationTypeSelectOptions(T)}
                              placeholder={T.t('gen_select_education_type')}
                              value={props.values.education_types}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('education_types', list);
                                props.setFieldValue(
                                  'education_type',
                                  list.map((item) => item.value)
                                );
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_education_type')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3 add-custom-tag">
                          <div className="react-select-container">
                            <label>{T.t('gen_grade')}</label>
                            <Select
                              id='select_grade'
                              className="react-select"
                              isMulti={true}
                              closeMenuOnSelect={false}
                              options={
                                this.props.selectOptions && this.props.selectOptions.grades
                                  ? this.props.selectOptions.grades
                                  : []
                              }
                              placeholder={T.t('gen_select_grade')}
                              value={props.values.grades}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('grades', list);
                                props.setFieldValue(
                                  'grades_ids',
                                  list.map((item) => item.value)
                                );
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_grade')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3 add-custom-tag">
                          <div className="react-select-container">
                            <label>{T.t('gen_section')}</label>
                            <Select
                              id='select_section'
                              className="react-select"
                              isMulti={true}
                              closeMenuOnSelect={false}
                              options={
                                this.props.selectOptions && this.props.selectOptions.sections
                                  ? this.props.selectOptions.sections
                                  : []
                              }
                              placeholder={T.t('gen_select_section')}
                              value={props.values.sections_object}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('sections_object', list);
                                props.setFieldValue(
                                  'sections',
                                  list.map((item) => item.value)
                                );
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_section')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mt-3 col-md-4">
                        <div className="form-input form-group date-picker">
                          <input
                            id="week"
                            name="week"
                            value={props.values.week}
                            onChange={props.handleChange}
                            type="text"
                            required
                          />
                          <span className="highlight" />
                          <span className="bar" />
                          <label htmlFor="week">{T.t('gen_week')}</label>
                          <i className="material-icons">filter_1</i>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3 add-custom-tag">
                          <div className="react-select-container">
                            <label>{T.t('gen_status_hour_assignment')}</label>
                            <Select
                              id='select_course_hour'
                              className="react-select"
                              isMulti={true}
                              closeMenuOnSelect={false}
                              options={CourseHourStatusTypes(T)}
                              placeholder={T.t('gen_status_hour_assignment')}
                              value={props.values.course_hour_status ? props.values.course_hour_status : null}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('course_hour_status', list);
                                props.setFieldValue(
                                  'course_hour_status_ids',
                                  list.map((item) => item.value)
                                );
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_instructor')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3 add-custom-tag">
                          <div className="react-select-container">
                            <label>{T.t('gen_status_classroom_assignment')}</label>
                            <Select
                              id='select_course_hour_classroom'
                              className="react-select"
                              isMulti={true}
                              closeMenuOnSelect={false}
                              options={CourseHourStatusTypes(T)}
                              placeholder={T.t('gen_status_classroom_assignment')}
                              value={props.values.course_hour_classroom_status ? props.values.course_hour_classroom_status : null}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('course_hour_classroom_status', list);
                                props.setFieldValue(
                                  'course_hour_classroom_status_ids',
                                  list.map((item) => item.value)
                                );
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_instructor')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3 add-custom-tag">
                          <div className="react-select-container">
                            <label>{T.t('gen_course_language')}</label>
                            <Select
                              id='select_course_language'
                              className="react-select"
                              isMulti={true}
                              filterOption={(option: any, query: any) =>
                                option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                              }
                              closeMenuOnSelect={true}
                              options={
                                this.props.selectOptions && this.props.selectOptions.course_languages_active
                                  ? this.props.selectOptions.course_languages_active
                                  : []
                              }
                              placeholder={T.t('gen_select_course_language')}
                              value={props.values.course_languages ? props.values.course_languages : null}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('course_languages', list);
                                props.setFieldValue(
                                  'course_language_ids',
                                  list.map((item) => item.value.toString())
                                );
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_course_language')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3 add-custom-tag">
                          <div className="react-select-container">
                            <label>{T.t('gen_course_environment')}</label>
                            <Select
                              id='select_course_environment'
                              className="react-select"
                              isMulti={true}
                              filterOption={(option: any, query: any) =>
                                option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                              }
                              closeMenuOnSelect={false}
                              options={CourseEnvironments(T)}
                              placeholder={T.t('gen_select_course_environment')}
                              value={props.values.course_environments ? props.values.course_environments : null}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('course_environments', list);
                                props.setFieldValue(
                                  'course_environment_ids',
                                  list.map((item) => item.value)
                                );
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_exam_environment')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3 add-custom-tag">
                          <div className="react-select-container">
                            <label>{T.t('gen_classroom_type')}</label>
                            <Select
                              id='select_type'
                              className="react-select"
                              isMulti={true}
                              filterOption={(option: any, query: any) =>
                                option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                              }
                              closeMenuOnSelect={false}
                              options={
                                this.props.selectOptions && this.props.selectOptions.classroom_types
                                  ? this.props.selectOptions.classroom_types.filter(filterClassroomTypesForEventModuleStatus(this.props.general_settings))
                                  : []
                              }
                              placeholder={T.t('gen_select_type')}
                              value={props.values.classroom_types ? props.values.classroom_types : null}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('classroom_types', list);
                                props.setFieldValue(
                                  'classroom_type_ids',
                                  list.map((item) => item.value.toString())
                                );
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_type')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mt-3 col-md-4">
                        <div className="form-input form-group date-picker">
                          <input
                            id="description"
                            name="description"
                            value={props.values.description}
                            onChange={props.handleChange}
                            type="text"
                            required
                          />
                          <span className="highlight" />
                          <span className="bar" />
                          <label htmlFor="description">{T.t('gen_description')}</label>
                          <i className="material-icons">title</i>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="mt-3 row">
                      <div className="col-6">
                        <button
                          id='button_arrow_upwoard'
                          type="button"
                          onClick={() => {
                            this.state.filterIsOpen = false;
                            this.setState(this.state);
                          }}
                          className="mt-2 mb-2 mw-none mt-md-0 mb-md-0 btn-gray btn"
                        >
                          <i className="material-icons">arrow_upward</i>
                        </button>
                        <button
                          id='button_delete_sweep'
                          type="reset"
                          onClick={props.handleReset}
                          className="mt-2 mb-2 ml-2 mw-none btn btn-danger mt-md-0 mb-md-0"
                        >
                          <i className="material-icons">delete_sweep</i>
                        </button>
                      </div>
                      <div className="text-right col-6">
                        <button
                          id='button_search'
                          type="button"
                          className="mt-2 mb-2 btn btn-blue mt-md-0 mb-md-0"
                          onClick={() => props.handleSubmit()}
                          disabled={props.isSubmitting}
                        >
                          <i className="mr-2 material-icons">search</i> {T.t('gen_search')}
                        </button>
                      </div>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
        <div className="col-12">
          <table
            className={this.props.compactTable ? "aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table table-course-hours-classrooms" : "aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table"}
            style={this.props.compactTable ? { cursor: 'pointer' } : {}}
          >
            {<CourseHoursListPageTableHeaders
              compactTable={this.props.compactTable}
              fnSelectAll={this.props.user && (this.props.user.role === 's' || this.props.user.role === 'a' || this.props.user.role === 'e' || this.props.user.role === 'p') ? (this.onSelectAll) : null}
              fnColumnSorter={this.sort}
              selected_ids={this.state.selected_ids}
              all_ids={this.state.all_ids}
              filters={this.state.filters}
              activityList={this.props.activityList}
            />}
            <tbody>
              {courseList && courseList.length ? (
                courseList.map((item: any) => {
                  const courseLocation = allSelectOptions.find(
                    (option) => option.value == item.activityInfo && item.activityInfo.lecture_location_id
                  );

                  let combinedCourses = item.activityInfo && item.activityInfo.combined_courses;

                  let hasCombinedCourses =
                    item.activityInfo && item.activityInfo.combined_courses && item.activityInfo.combined_courses.length > 0;

                  if (hasCombinedCourses) {
                    this.state.combined_list_items && this.state.combined_list_items.push(item.course_id);
                  }

                  let tableContent = null;

                  let keyId = Math.random() * 1000

                  if (item.activityInfo) {
                    if (this.props.compactTable) {
                      tableContent = (
                        <tr className="table-course-hours-classrooms-tr" key={keyId}>
                          <td data-cell="select">
                            {this.props.user && (this.props.user.role === 's' || this.props.user.role === 'a' || this.props.user.role === 'e' || this.props.user.role === 'p') ? (
                              <div className="tick-radio position-relative">
                                <input
                                  id='check_course'
                                  type="checkbox"
                                  className="form-radio"
                                  onChange={this.onSelectCourseCheckbox}
                                  checked={this.state.selected_ids && this.state.selected_ids.indexOf(item.course_hour_id ? item.course_hour_id : -1) > -1}
                                  data-id={item.course_hour_id}
                                  data-name={
                                    item.activityInfo && item.activityInfo.course_code + ' - ' + item.activityInfo.course_name
                                  }
                                  data-item={JSON.stringify(item)}
                                />
                              </div>
                            ) : null}
                          </td>
                          <td
                            key={'course-' + item.course_id}
                            data-title={item.activityInfo && item.activityInfo.course_name}
                            data-id={item.course_hour_id}
                            data-course_id={item.course_id}
                            data-name={item.activityInfo && item.activityInfo.course_code + ' - ' + item.activityInfo.course_name}
                            data-item={JSON.stringify(item)}
                            onClick={this.onShowCourseProgram}
                            className="table-course-hours-classrooms-div"
                            style={
                              this.state.selectedActivityNo == item.course_id
                                ? {
                                  backgroundColor: 'rgba(218, 225, 232, 0.96)',
                                  border: '1px solid darkgrey'
                                }
                                : {}
                            }>
                            <table>
                              <tbody>
                                <tr>
                                  <td className="examp-compt-select">
                                    {item.status ? (
                                      <i className="material-icons" style={{ color: '#33c900c4' }}>
                                        check_circle
                                      </i>
                                    ) : (
                                      <i className="material-icons" style={{ color: '#ff0000c4' }}>
                                        cancel
                                      </i>
                                    )}
                                  </td>
                                  <td className="examp-compt-select">
                                    {(item.activityInfo && item.activityInfo.lecture_location_id && CourseTermLectureLocationsForSolution(T).map((x: any) => x.value).includes(item.activityInfo.lecture_location_id)) ? (
                                      <i className="material-icons" style={{ color: '#2A8CD9' }}>
                                        vpn_lock
                                      </i>
                                    ) : (item.status_classroom_assignment ? (
                                      <i className="material-icons" style={{ color: '#33c900c4' }}>
                                        check_circle
                                      </i>
                                    ) : (
                                      <i className="material-icons" style={{ color: '#ff0000c4' }}>
                                        cancel
                                      </i>
                                    ))}
                                  </td>
                                  <td className="text-center table-course-hours-classrooms-td-8">
                                    <div id="clickList" className="table-scrollable-td">{item.activityInfo && `${item.activityInfo.activity_no} - (${item.activityInfo.course_code.split(';').join(', ')}) ${item.activityInfo.course_name.split(';').join(', ')}`}</div>
                                  </td>
                                  <td className="text-center table-course-hours-classrooms-td-8" >
                                    {
                                      item.activityInfo && item.activityInfo.programs && item.activityInfo.programs.length > 0 ?
                                        <div className="table-scrollable-td">{item.activityInfo.programs && item.activityInfo.programs.map((i: any, index: any) => (index == item.activityInfo!.programs!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                                    }
                                  </td>
                                  <td className="text-center table-course-hours-classrooms-td-8" >
                                    {
                                      item.activityInfo && item.activityInfo.instructors && item.activityInfo.instructors.length > 0 ?
                                        <div className="table-scrollable-td">{item.activityInfo.instructors && item.activityInfo.instructors.map((i: any, index: any) => (index == item.activityInfo!.instructors!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                                    }
                                  </td>
                                  <td className="table-course-hours-classrooms-td-8 text-center" >
                                    {item.activityInfo && `${item.activityInfo.lesson_count} - ${ActivityTypesLabel(item.activityInfo.activity_type, T)} - ${item.activityInfo && item.activityInfo.lecture_location && LectureLocationsLabel(item.activityInfo.lecture_location.label, T)} - `}
                                    <div className="table-scrollable-td">{item.activityInfo.week ? item.activityInfo.week.split(',').join(', ') : '-'}</div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      );
                    } else {
                      tableContent = (
                        <tr key={'course-' + item.course_hour_id} data-title={item.activityInfo.course_name}>
                          <td data-cell="select">
                            {this.props.user && (this.props.user.role === 's' || this.props.user.role === 'a' || this.props.user.role === 'e' || this.props.user.role === 'p') ? (
                              <div className="tick-radio position-relative">
                                <input
                                  id='check_course_hour'
                                  type="checkbox"
                                  className="form-radio"
                                  checked={
                                    this.state.selected_ids &&
                                    this.state.selected_ids.indexOf(item.course_hour_id ? item.course_hour_id : -1) > -1
                                  }
                                  data-id={item.course_hour_id}
                                  data-name={
                                    item.activityInfo && item.activityInfo.course_code + ' - ' + item.activityInfo.course_name
                                  }
                                  data-item={JSON.stringify(item)}
                                  onChange={this.onSelectCourseCalledFromCoursesTab}
                                />
                              </div>
                            ) : null}
                          </td>
                          <td scope="row" data-label={T.t('gen_status')}>
                            <div className="ml-1 mr-4 tags">
                              <button
                                id='button_status'
                                className={
                                  `small-tag text-uppercase` + (item.activityInfo.status == 1 ? ` tag-green` : ` tag-red`)
                                }
                              >
                                {GT.GetActiveStatus(item.activityInfo.status)}
                              </button>
                            </div>
                          </td>
                          <td data-label="Onay" className="text-center">
                            {item.status == 1 ? (
                              <span
                                id='span_approve'
                                className="badge badge-success"
                                style={{
                                  fontSize: '95%',
                                  textAlign: 'center'
                                }}
                              >
                                {T.t('gen_badge_assigned')}
                              </span>
                            ) : (
                              <span
                                id='span_waiting'
                                className="badge badge-warning"
                                style={{
                                  fontSize: '95%',
                                  textAlign: 'center'
                                }}
                              >
                                {T.t('gen_badge_unassigned')}
                              </span>
                            )}
                            {combinedCourses && combinedCourses.length > 0 ? (
                              <span
                                id='span_combined_activities'
                                className="badge badge-danger"
                                style={{
                                  fontSize: '95%',
                                  textAlign: 'center'
                                }}
                              >
                                {T.t('gen_combined_activities')}
                              </span>
                            ) : null}
                          </td>
                          <td data-label="classroom_assignment_status" className="text-center">
                            {item.status_classroom_assignment == 1 ? (
                              <span
                                className="badge badge-success"
                                style={{
                                  fontSize: '95%',
                                  textAlign: 'center'
                                }}
                              >
                                {T.t('gen_badge_assigned')}
                              </span>
                            ) : (
                              <span
                                className="badge badge-warning"
                                style={{
                                  fontSize: '95%',
                                  textAlign: 'center'
                                }}
                              >
                                {T.t('gen_badge_unassigned')}
                              </span>
                            )}
                          </td>
                          <td scope="row" data-label={T.t('gen_activity_no')} className="text-center">{item.activityInfo.activity_no}</td>
                          <td scope="row" data-label={T.t('gen_code')} className="text-center">
                            <div className="table-scrollable-td">{item.activityInfo.course_code ? item.activityInfo.course_code.split(';').join(', ') : '-'}</div>
                          </td>
                          <td scope="row" data-label={T.t('gen_name')} className="text-center">
                            <div className="table-scrollable-td">{item.activityInfo.course_name ? item.activityInfo.course_name.split(';').join(', ') : '-'}</div>
                          </td>
                          <td data-label={T.t('gen_activity_type')} className="text-center">{ActivityTypesLabel(item.activityInfo.activity_type, T)}</td>
                          <td scope="row" data-label={T.t('gen_lesson_count')} className="text-center">{item.activityInfo.lesson_count}</td>
                          <td scope="row" data-label={T.t('gen_lesson_location')} className="text-center">
                            {item.activityInfo.lecture_location && LectureLocationsLabel(item.activityInfo.lecture_location.label, T)}
                          </td>
                          <td scope="row" data-label={T.t('gen_education_type')} className="text-center">
                            {item.activityInfo.education_type === EducationTypeSelectOptions(T)[0].value ?
                              EducationTypeSelectOptions(T)[0].label :
                              item.activityInfo.education_type === EducationTypeSelectOptions(T)[1].value ?
                                EducationTypeSelectOptions(T)[1].label :
                                item.activityInfo.education_type === EducationTypeSelectOptions(T)[2].value ?
                                  EducationTypeSelectOptions(T)[2].label : item.activityInfo.education_type
                            }
                          </td>
                          <td className="text-center">
                            {
                              item && item.activityInfo.faculties && item.activityInfo.faculties.length > 0 ?
                                <div className="table-scrollable-td">{item.activityInfo.faculties && item.activityInfo.faculties.map((i: any, index: any) => (index == item.activityInfo.faculties!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                            }
                          </td>
                          <td className="text-center">
                            {
                              item && item.activityInfo.programs && item.activityInfo.programs.length > 0 ?
                                <div className="table-scrollable-td">{item.activityInfo.programs && item.activityInfo.programs.map((i: any, index: any) => (index == item.activityInfo.programs!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                            }
                          </td>
                          <td className="text-center">
                            {
                              item && item.activityInfo.classes && item.activityInfo.classes.length > 0 ?
                                <div className="table-scrollable-td">{item.activityInfo.classes && item.activityInfo.classes.map((i: any, index: any) => (index == item.activityInfo.classes!.length - 1) ? <>{i ? i : '-'}<br /></> : <>{i ? i : '-'},<br /></>)}</div> : '-'
                            }
                          </td>
                          <td className="text-center">
                            {
                              item && item.activityInfo.sections && item.activityInfo.sections.length > 0 ?
                                <div className="table-scrollable-td">{item.activityInfo.sections && item.activityInfo.sections.map((i: any, index: any) => (index == item.activityInfo.sections!.length - 1) ? <>{i ? i : '-'}<br /></> : <>{i ? i : '-'},<br /></>)}</div> : '-'
                            }
                          </td>
                          <td className="text-center">
                            {
                              item && item.activityInfo.instructors && item.activityInfo.instructors.length > 0 ?
                                <div className="table-scrollable-td">{item.activityInfo.instructors && item.activityInfo.instructors.map((i: any, index: any) => (index == item.activityInfo.instructors!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                            }
                          </td>
                          <td scope="row" data-label={T.t('gen_student_count')} className="text-center">
                            {item.activityInfo.student_count}
                          </td>
                          <td scope="row" data-label={T.t('gen_week')} className="text-center">
                            <div className="table-scrollable-td">{item.activityInfo.week ? item.activityInfo.week.split(',').join(', ') : '-'}</div>
                          </td>
                          <td scope="row" data-label={T.t('gen_course_type')} className="text-center">
                            {item.activityInfo.course_type}
                          </td>
                          <td data-label={T.t('gen_description')} className="text-center">
                            {item && item.activityInfo.description && item.activityInfo.description.length ?
                              <div className="table-scrollable-td">{item.activityInfo.description}</div> : '-'}
                          </td>
                          <td data-label={T.t('gen_actions')} className="table-buttons">
                            <div className="table-buttons-wrapper">
                              {combinedCourses && combinedCourses.length ? (
                                <button
                                  id='button_library_books'
                                  data-toggle="tooltip"
                                  title={T.t('gen_combined_activities')}
                                  className="btn btn-light btn-sm table-button"
                                  onClick={() => {
                                    this.saveCombinedCourses(combinedCourses, item.activityInfo);
                                  }}
                                >
                                  <span className="d-block">
                                    <i className="material-icons">library_books</i>
                                  </span>
                                </button>
                              ) : (
                                this.props.user && (this.props.user.role === 's' || this.props.user.role === 'a' || this.props.user.role === 'e' || this.props.user.role === 'p') ? (
                                  <button
                                    id='button_edit'
                                    data-toggle="tooltip"
                                    title={T.t('gen_activity_edit')}
                                    className="btn btn-light btn-sm table-button"
                                    onClick={() => {
                                      this.setState({
                                        ...this.state,
                                        activityFormIsOpen: !this.state.activityFormIsOpen,
                                        editActivity: item
                                      });
                                    }}
                                  >
                                    <span className="d-block">
                                      <i className="material-icons">edit</i>
                                    </span>
                                  </button>
                                ) : null
                              )}
                              <button
                                id='button_access_time'
                                data-toggle="tooltip"
                                data-id={item.activityInfo.course_id}
                                onClick={() =>
                                  this.props.dispatch(
                                    Actions.ShowModal({
                                      title: T.t('gen_hours'),
                                      body: T.t("gen_info_class_hours_classrooms_tab"),
                                      name: 'examPeriod_delete',
                                      icon: 'error_outline',
                                      iconColor: 'red',
                                      cancel: T.t('gen_close')
                                    })
                                  )
                                }
                                title={T.t('gen_display_hours')}
                                className="btn btn-light btn-sm table-button"
                              >
                                <span className="d-block" data-toggle="modal" data-target="#addNew">
                                  <i className="material-icons">access_time</i>
                                </span>
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    }
                  }
                  return tableContent;
                })
              ) : (
                <tr>
                  <td colSpan={24}>
                    <Announce title={T.t('gen_no_records_found')} />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="row-options justify-content-end">
            <div className="page-sorting d-flex align-items-center justify-content-center" style={{ marginTop: '5px' }}>
              {this.state.activity_list && this.state.activity_list.length > 0 ? (
                <Paginate filters={this.props.filters} onPageChange={this.onPageChange} />
              ) : null}
            </div>
          </div>
        </div >
        {
          this.state.classroomAddModalIsOpen && (
            <ClassroomAddModal
              selectedActivity={this.state.selected_activity ? this.state.selected_activity.activityInfo : null}
              selectedActivities={this.state.selected_activities ? this.state.selected_activities : null}
              modalIsOpen={this.state.classroomAddModalIsOpen}
              onClose={this.switchClassroomModalStatus}
              onUpdateList={() => this.getCourseHours()}
              calledFromCourseTab={true}
              selectedCourseIDs={this.state.selected_course_ids}
            />
          )
        }
        {
          this.state.combineActivitiesModalIsOpen && (
            <CombineActivitiesModal
              courseList={this.state.selected_all_activities && this.state.selected_all_activities.length > 0 ? this.state.selected_all_activities : this.state.selected_activities}
              combinedDataList={this.state.combined_activities}
              modalIsOpen={this.state.combineActivitiesModalIsOpen}
              coursePeriodCourseObject={this.state.activityObject}
              onClose={this.switchCombineActivitiesModalStatus}
              showCoursesOnly={this.state.hasCombinedActivities}
              onUpdateList={() => this.getCourseHours()}
            />
          )
        }
        {
          this.state.activityCopyModalIsOpen && (
            <CopyCourseModal
              course={this.state.selected_activities}
              modalIsOpen={this.state.activityCopyModalIsOpen}
              onClose={this.switchCopyExamModalStatus}
              showCoursesOnly={this.state.hasCombinedActivities}
              onUpdateList={() => this.getCourseHours()}
            />
          )
        }
        {
          this.state.activityFormIsOpen && (
            <CourseEditForm
              activity={this.state.editActivity}
              formIsOpen={this.state.activityFormIsOpen}
              onClose={() => {
                this.setState({
                  activityFormIsOpen: !this.state.activityFormIsOpen
                });
                this.getCourseHours();
              }}
            />
          )
        }
        {
          this.state.downloadHoursModalIsOpen ? (
            <HoursExportModal
              onClose={this.switchDownloadHoursModalStatus}
              modalIsOpen={this.state.downloadHoursModalIsOpen}
              termID={this.state.filters.term_id}
              sectionType={SectionTypes.Planning_Courses}
            />
          ) : null
        }
      </div >
    );

    return <div className="p-0 container-fluid"><Spinner name="course-hours-spinner" />{Table}</div>;
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.IActivityPageProps): Types.IActivityPageProps => {
  if (!store) {
    return ownProps;
  }
  const newProps: Types.IActivityPageProps = Object.assign({}, ownProps, {
    user: store.state.user,
    activityList:
      store.state.examPeriodModal &&
      store.state.examPeriodModal.courseterm_course_hours &&
      store.state.examPeriodModal.courseterm_course_hours.activity_list,
    results: store.state.activity_page && store.state.activity_page.results,
    filters: store.state.examPeriodModal && store.state.examPeriodModal.filters,
    all_ids:
      store.state.examPeriodModal &&
      store.state.examPeriodModal.courseterm_course_hours &&
      store.state.examPeriodModal.courseterm_course_hours.all_ids,
    all_course_ids:
      store.state.examPeriodModal &&
      store.state.examPeriodModal.courseterm_course_hours &&
      store.state.examPeriodModal.courseterm_course_hours.all_course_ids,
    selectOptions: store.state.select_options && store.state.select_options.activityPage,
    is_related: store.state.activity_page && store.state.activity_page.is_related,
    programs_related_faculty: store.state.select_options && store.state.select_options.programs_related_faculty,
    general_settings: store.state.general_settings,
  });
  return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  return false;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(CourseAddModal);

export default container;
